import React from 'react';

const QuestionsDisplay = ({sampleQuestions}) => {
  const questionsArray = sampleQuestions.split(', ');

  return (
    <div style={styles.container}>
      {questionsArray.map((question, index) => (
        <p key={index} style={styles.question}>{question}</p>
      ))}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding:"3px",
    marginBottom:"5%"
  },
  question: {
    fontSize: '1.1rem',
    fontWeight:"400",
    margin: '6px 0',
    color: "#616161",
    opacity: "1",
    fontFamily: "Poppins,sans-serif"
  },
};

export default QuestionsDisplay;