import { initializeApp } from "firebase/app";
import { getAuth, signInWithRedirect, signInWithPopup, GoogleAuthProvider, signOut, getRedirectResult, signInWithCustomToken } from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";

import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAULPJNsevZ7usWogR472QxcOaLF3AMleg",
  authDomain: "spiritualtech-7c560.firebaseapp.com",
  projectId: "spiritualtech-7c560",
  storageBucket: "spiritualtech-7c560.appspot.com",
  messagingSenderId: "50801977295",
  appId: "1:50801977295:web:ce4d7fab16e1d38f6914ba",
  measurementId: "G-NR854M8XF6"
};

export const app = initializeApp(firebaseConfig);
export const provider = new GoogleAuthProvider(); 

export const auth = getAuth(app); 

export const getRedirectFunc = () => getRedirectResult(auth);
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
export const signInWithGoogleRedirect = () => signInWithRedirect(auth, new GoogleAuthProvider());
export const signOutFromGoogle = () => signOut(auth);

export const db = getFirestore(app); //exporting db reference

export {signInWithCustomToken}

// const analytics = getAnalytics(app);
// export const auth =  getAuth(app);
// export const googleProvider = new GoogleAuthProvider();