import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Paper, Box } from '@mui/material';

// Helper function to calculate age from DOB
const calculateAge = (dob) => {
  const today = new Date();
  const birthDate = new Date(dob);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  const dayDiff = today.getDate() - birthDate.getDate();

  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age--;
  }

  const months = monthDiff < 0 ? 12 + monthDiff : monthDiff;
  const days = dayDiff < 0 ? new Date(today.getFullYear(), today.getMonth(), 0).getDate() + dayDiff : dayDiff;

  return { years: age, months, days };
};

// Function to get life phase, milestones, and advice based on age
const getLifePhaseInfo = (age) => {
  if (age < 20) {
    return {
      phase: "Adolescence",
      milestone: "Education and early career exploration",
      advice: "Focus on gaining knowledge, exploring interests, and developing a strong foundation for your future."
    };
  } else if (age >= 20 && age < 30) {
    return {
      phase: "Early Adulthood",
      milestone: "Career establishment and personal growth",
      advice: "Build your career, foster meaningful relationships, and continue personal development."
    };
  } else if (age >= 30 && age < 40) {
    return {
      phase: "Midlife",
      milestone: "Career advancement and family life",
      advice: "Focus on achieving career goals, maintaining work-life balance, and nurturing personal relationships."
    };
  } else if (age >= 40 && age < 50) {
    return {
      phase: "Established Adulthood",
      milestone: "Leadership roles and life stability",
      advice: "Leverage your experience for leadership roles, and focus on long-term goals and stability."
    };
  } else if (age >= 50 && age < 60) {
    return {
      phase: "Pre-Retirement",
      milestone: "Preparing for retirement and legacy planning",
      advice: "Prepare for retirement, evaluate your legacy, and focus on personal satisfaction and health."
    };
  } else if (age >= 60) {
    return {
      phase: "Retirement",
      milestone: "Enjoying retirement and reflecting on life achievements",
      advice: "Enjoy your retirement years, engage in hobbies, and spend time with loved ones. Reflect on your life and its accomplishments."
    };
  } else {
    return {
      phase: "Unknown",
      milestone: "N/A",
      advice: "N/A"
    };
  }
};

const AgeCalculator = () => {
  const [dob, setDob] = useState('');
  const [result, setResult] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    const { years, months, days } = calculateAge(dob);
    const { phase, milestone, advice } = getLifePhaseInfo(years);

    setResult(
      `<div><strong style="font-size: 16px; color: black;">Age: </strong> <strong>${years} years, ${months} months, ${days} days</strong></div>
      <div><strong style="font-size: 16px; color: black;">Life Phase: </strong> ${phase}</div>
      <div><strong style="font-size: 16px; color: black;">Key Milestone: </strong> ${milestone}</div>
      <div><strong style="font-size: 16px; color: black;">Advice: </strong> ${advice}</div>`
    );
  };

  return (
    <Container
      sx={{
        background: 'linear-gradient(to right, #ffeb3b, #e3f2fd)',
        borderRadius: '10px',
        padding: 4,
        boxShadow: 3,
        maxWidth: '500px',
        margin: 'auto',
        marginTop: 5
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', color: '#fff', marginBottom: 2 }}>
          Age Calculator
        </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Date of Birth (DD-MM-YYYY)"
          variant="outlined"
          margin="normal"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={dob}
          onChange={(e) => setDob(e.target.value)}
          required
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          sx={{ mt: 2 }}
        >
          Calculate Age and Life Phase
        </Button>
      </form>
      {result && (
        <Box
          sx={{
            padding: 3,
            marginTop: 3,
            backgroundColor: '#fff',
            color: '#003c8f',
            boxShadow: 2
          }}
          component={Paper}
          dangerouslySetInnerHTML={{ __html: result }}
        />
      )}
    </Container>
  );
};

export default AgeCalculator;
