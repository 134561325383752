import React from "react";
import { useStateValue } from '../statemanagement/StateProvider';
import { actionTypes } from '../statemanagement/reducer';
import "./BasicChat.css"
import { modes } from "../mock/PromptAssistanceExamples";
import { useState, useEffect } from "react";
import Header from "./Header";
import AppFooter from "./AppFooter";
import PlacesAutocomplete from "../components/PlacesAutocomplete";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom"
import MobileDateComponent from "./MobileDateComponent";
import RowRadioButtonsGroup from "../components/RowRadioButtonsGroup";
import axios from "axios";
import { getUrl } from "../helpers/envUrls";
import { TypeAnimation } from 'react-type-animation';
import mixpanelService from "../mixpanelService";
import mixpanel from "mixpanel-browser";
import { calculateAge } from "../helper/calculateAge";

const EditHoroscopeDetails = () => {
    const [index, setIndex] = useState(0);
    const [{ dob, tob, geo, gender, city, userCred }, dispatch] = useStateValue();
    const navigate = useNavigate();
    const [hasEditedDateTime, setHasEditedDateTime] = useState(false);
    const [currCity, setCurrCity] = useState(city || "");
    const [currGeo, setCurrGeo] = useState(geo || "");

    useEffect(() => {
        const savedCity = localStorage.getItem('city');
        const savedGeo = localStorage.getItem('geo');

        if (savedCity && savedGeo) {
            setCurrCity(savedCity);
            setCurrGeo(JSON.parse(savedGeo));
        } else {
            const fetchRealTimeCity = async () => {
                try {
                    const response = await axios.get('https://ipinfo.io');
                    const locationData = response.data;
                    const cityFromIP = locationData.city;
                    const geoFromIP = locationData.loc.split(',');

                    setCurrCity(cityFromIP);
                    setCurrGeo({ lat: geoFromIP[0], lng: geoFromIP[1] });

                    console.log("Using real-time city from IP:", cityFromIP, geoFromIP);
                } catch (error) {
                    console.error('Error fetching real-time location:', error);
                }
            };

            fetchRealTimeCity();
        }
    }, []);

    const handleSubmit = async () => {
        const eventProperties = {
            logged_in: userCred ? true : false
        };

        if (dob && tob) {
            localStorage.setItem('dob', dob);
            localStorage.setItem('tob', tob);
            eventProperties.dob = dob;
            eventProperties.tob = tob;
            eventProperties.age = calculateAge(dob);
        }

        if (city && geo) {
            localStorage.setItem('city', city);
            localStorage.setItem('geo', JSON.stringify(geo));
            eventProperties.birth_city = city;
        }

        if (gender) {
            localStorage.setItem('gender', gender);
            eventProperties.gender = gender;
        }

        if (userCred) {
            try {
                const response = await axios.post(`${getUrl()}/save-horoscope-details`, {
                    uid: userCred.uid,
                    dob,
                    tob,
                    placeOfBirth: geo, 
                    city: city,
                    gender,
                });

                if (response.data.success) {
                    console.log('Horoscope details saved to backend successfully.');
                } else {
                    console.error('Failed to save horoscope details to backend:', response.data.message);
                }
            } catch (error) {
                console.error('Error saving horoscope details to backend:', error);
            }
        }

        navigate("/");
        mixpanelService.trackEvent('Updated Birth Details', eventProperties);
        mixpanel.people.set(eventProperties);
        //console.log("Details saved to localStorage & backend if logged in:", { dob, tob, city, geo, gender });
    };

    useEffect(() => {
        dispatch({ type: actionTypes.SET_STREAMING, streaming: false });
        const interval = setInterval(() => {
            setIndex(u => (u + 1) % modes.length);
        }, 4100);
        return () => clearInterval(interval);
    }, [dispatch]);

    return (
        <div className="astroSkyLandingPageCommon">
            <Header showLogin={true} />
            <div className="container">
            <TypeAnimation sequence={[
                                    '100% Privacy',
                                    3000,
                                    'No Data Stored',
                                    3000,
                                    'Trusted by 4 Lakh Users',
                                    3000,
                                ]} wrapper="span" speed={10} style={{textAlign:"center", marginTop:"5%", fontSize: "1.3em", fontWeight: "450", lineHeight: "2.1rem", display: "inline-block", color: "rgb(52, 52, 52)", fontWeight: "401" }} repeat={1} />
               <br/>
                <div className="container_body" style={{ padding: "0.9rem" }}>
                    <MobileDateComponent edit={true} setHasEditedDateTime={setHasEditedDateTime} />
                    <PlacesAutocomplete edit={true} city={currCity} geo={currGeo} />
                    <RowRadioButtonsGroup dispatch={dispatch} gender={gender} /> <br /> <br />
                    <Button className="chatStyle" onClick={handleSubmit}>
                        Submit
                    </Button>
                    <br /> <br /> <br />
                    <i style={{ fontSize: "0.9rem" }}>
                        * Birth Date, Time and Location is used to create your Kundli
                    </i>
                    <br /><br /> <br />
                </div>
            </div>
            <AppFooter showHome={false} showSearch={false} showNumerology={false} showWrite={true} showBack={false} />
        </div>
    );
};

export default EditHoroscopeDetails;