import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom"
import Header from './Header';
import Footer from './Footer';
import AppFooter from './AppFooter';
import { TypeAnimation } from "react-type-animation";
import "./Business.css"

const Business = () => {

    const navigate = useNavigate();

    useEffect(()=>{
        window.gtag('event', 'Business');
    },[])

    return (
        <div>
            <div className="container" style={{padding:"3.9%"}}>
                <Header showLogin={false} />
                <div style={{ marginTop: "2%" }}>
                    <div className="">
                        <div className="business-messaging" style={{ marginTop: "3%" }}>
                            {/* <h1 style={{fontWeight:400}}>Want to Build an AI for your SME Business or Startup?</h1> */}
                            <TypeAnimation sequence={[
                             "Want to Build an AI for your SME?",
                             500,
                             "Want to Build a Custom AI bot?",
                             500,
                             "Want to make your business more cost effective with AI?",
                             500,
                             "Want to integrate AI Images, Voice?",
                             500,
                             "Reach out to us if you have an interesting use case",
                             500,
                             "Want to discuss how AI can help your business?",

                        ]} wrapper="span" speed={2} style={{ fontSize: "1.1em", lineHeight: "2.1rem", color: "rgb(52, 52, 52)", fontWeight: "401", opacity: "0.9" }} />
                            <br />
                            

                            <br />
                            <h3 style={{fontWeight:300}}>Kindly reach out to us via email to discuss more: <a href="mailto:tigerchatai@gmail.com">tigerchatai@gmail.com</a></h3>
                            <br />
                            <br />
                            <img src="/business.webp" style={{width:"100%"}}/>
                            <br/>
                            <br />
                            <br/>
                            <h2 style={{textAlign:"center"}}>Our Founders Story</h2>
                            <br/>
                            <br/>
                            <h3 style={{fontWeight:300}}>
                            Our founder's career at Microsoft stands out as a testament to his profound impact on the Technology sector. During his time at Microsoft, he shaped products infused with AI that are currently used by more than a <b>billion</b> people around the world. 
                            <br/>  <br/>
                            Holding <b>multiple patents in AI</b>, he was one of the founding members of the <b>Microsoft Copilot Generative AI </b> suite of products that 
                            are set to revolutionize the way in which humans work. 
                            <br/> <br/>
                            His entrepreneurial journey led to the creation of <b>TigerChat</b>, an application that has achieved remarkable success in a very short period of time, with over 170,000 active users in India and a presence in more than 50 countries worldwide. 
                            This achievement underscores his knack for developing applications that resonate with a global audience. 
                            <br/> <br/>
                            <b>If you would like to discuss how to integrate 
                            AI in your business or have an interesting usecase please reach out to us: <a href="mailto:tigerchatai@gmail.com">tigerchatai@gmail.com</a></b>
                            <br/> <br/>
                            </h3>
                           <br/>
                           
                            <br />
                        </div>
                        <br />

                    </div>
                    <br />

                </div>

            </div>
            {/* <Footer/> */}
            <AppFooter/>
        </div>


    );
};

export default Business;
