import axios from 'axios';

const API_URL = 'https://steadfast-cows-9445c3a50f.strapiapp.com';

export const getBlogs = async (language = 'en') => {
  try {
    const response = await axios.get(`${API_URL}/api/blogs?populate=*&locale=${language}`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching blogs:', error);
    throw error;
  }
};

export const getContent = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/contents?populate=*`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching blogs:', error);
    throw error;
  }
};

export const getCategories = async (language = 'en') => {
  try {
    const response = await axios.get(`${API_URL}/api/categories?populate=*&locale=${language}`);
    console.log(response, "--hindi");
    return response.data.data;
  } catch (error) {
    console.error('Error fetching blogs:', error);
    throw error;
  }
};

export const getBlogsByCategory = async (categorySlug, language = 'en') => {
  try {
    const response = await axios.get(
      `${API_URL}/api/blogs?populate=*&&locale=${language}&filters[categories][slug][$eq]=${categorySlug}`
    );
    return response.data.data;
  } catch (error) {
    console.error('Error fetching blogs by category:', error);
    throw error;
  }
};
