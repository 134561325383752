import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Box, Paper } from '@mui/material';
import { getUrl } from '../../helpers/envUrls';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';

const BabyNameGenerator = () => {
  const [dob, setDob] = useState('');
  const [tob, setTob] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [result, setResult] = useState(null);
  const [birthplace, setBirthplace] = useState('');

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 300,
  });

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      setLatitude(lat);
      setLongitude(lng);
      setBirthplace(address);
    } catch (error) {
      console.error('Error fetching latitude and longitude:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formattedDob = dob.split('-').reverse().join('/');
    const userDetail = {
        dob: formattedDob,
        tob,
        lat: latitude,
        lon: longitude,
      };
  
    try {
      const response = await fetch(`${getUrl()}/get-horoscope-detail`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({"userDetail": userDetail})
      });
      
      const data = await response.json();
      console.log(data, "---data");
      if (data?.response) {
        const luckyNameStart = data.response.lucky_name_start.join(', ');
        const birthDetails = `
          <table>
            <tr><td><strong>Day of Birth : </strong></td><td>${data.response.panchang.day_of_birth}</td></tr>
            <tr><td><strong>Day Lord : </strong></td><td>${data.response.panchang.day_lord}</td></tr>
            <tr><td><strong>Ascendant:</strong></td><td>${data.response[0].zodiac}</td></tr>
            <tr><td><strong>Ascendant Lord : </strong></td><td>${data.response[0].zodiac_lord}</td></tr>
            <tr><td><strong>Moon Sign : </strong></td><td>${data.response[2].zodiac}</td></tr>
            <tr><td><strong>Nakshatra : </strong></td><td>${data.response[2].nakshatra}</td></tr>
            <tr><td><strong>Nakshatra Pada : </strong></td><td>${data.response[2].nakshatra_pada}</td></tr>
            <tr><td><strong>Lucky Colour : </strong></td><td>${data.response.lucky_colors.join(', ')}</td></tr>
            <tr><td><strong>Lucky Number : </strong></td><td>${data.response.lucky_num.join(', ')}</td></tr>
            <tr><td><strong>Karana : </strong></td><td>${data.response.panchang.karana}</td></tr>
            <tr><td><strong>Yoga : </strong></td><td>${data.response.panchang.yoga}</td></tr>
            <tr><td><strong>Tithi : </strong></td><td>${data.response.panchang.tithi}</td></tr>
          </table>
        `;
        
        setResult({
          luckyNameStart: luckyNameStart,
          birthDetails: birthDetails
        });
      }
    } catch (error) {
      console.error('Error fetching baby name details:', error);
    }
  };



  return (
    <Container
      sx={{
        background: 'linear-gradient(to right, #ffeb3b, #e3f2fd)',
        borderRadius: '10px',
        padding: 4,
        boxShadow: 3,
        maxWidth: '500px',
        margin: 'auto',
        marginTop: 5,
      }}
    >
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{ textAlign: 'center', color: '#fff', marginBottom: 2 }}
      >
        Baby Name Generator
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Date of Birth (DD-MM-YYYY)"
          variant="outlined"
          margin="normal"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={dob}
          onChange={(e) => setDob(e.target.value)}
          required
        />
        <TextField
          fullWidth
          label="Time of Birth (HH:MM)"
          variant="outlined"
          margin="normal"
          type="time"
          InputLabelProps={{ shrink: true }}
          value={tob}
          placeholder="HH:MM"
          onChange={(e) => setTob(e.target.value)}
          required
        />
        <TextField
          fullWidth
          label="Place of Birth"
          variant="outlined"
          margin="normal"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={!ready}
          required
        />
        {status === 'OK' && (
          <Box sx={{ maxHeight: 200, overflowY: 'auto' }}>
            {data.map(({ description }) => (
              <Button
                key={description}
                fullWidth
                onClick={() => handleSelect(description)}
                sx={{ justifyContent: 'start' }}
              >
                {description}
              </Button>
            ))}
          </Box>
        )}
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          sx={{ mt: 2 }}
        >
          Generate Baby Name
        </Button>
      </form>

      {result && (
        <>
          <Box
            sx={{
              padding: 3,
              marginTop: 3,
              backgroundColor: '#fff',
              color: '#003c8f',
              boxShadow: 2,
            }}
            component={Paper}
          >
            <Typography variant="h6">Starting Alphabet for Baby Name</Typography>
            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: result.luckyNameStart }} />
          </Box>

          <Box
            sx={{
              padding: 3,
              marginTop: 3,
              backgroundColor: '#fff',
              color: '#003c8f',
              boxShadow: 2,
            }}
            component={Paper}
          >
            <Typography variant="h6">Other Important Birth Details</Typography>
            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: result.birthDetails }} />
          </Box>
        </>
      )}
    </Container>
  );
};

export default BabyNameGenerator;
