import React from "react";
import "./BasicChat.css";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../statemanagement/StateProvider";
import "./BasicChat.css"; 
import { actionTypes } from "../statemanagement/reducer";
import RightSidebar from "../components/RightSidebar";

const Header = ({ showLine = true, displayAI = true }) => {
    const [_,dispatch] = useStateValue(); 
    const navigate = useNavigate(); 

    return (<div className="headerStyle" >
        <div className="container_header_banner" style={{ justifyContent: "space-between" }}>
            <div>
                <div style={{ display: "flex", alignItems:"center" }} onClick={_ => { navigate("/"); }}>
                    <img src="/icons/horoscope.png" alt="HiAstro" style={{ paddingRight: "3.5%", width:"45px", height:"45px" }} />
                    <div className="headerStyle_Label">
                        <h1 style={{ fontSize: "30px", fontWeight: 500, marginLeft: "2%", opacity: "0.9", width:"fit-content" }}>HiAstro</h1>
                        { displayAI && <h5><i style={{opacity:"0.5", fontWeight:500, padding: "2px" }}>Powered by AI</i></h5>}
                        { !displayAI && <h5><i style={{padding: "2px", display:"none" }}>Powered by AI</i></h5>}
                    </div> 
                </div>
            </div>
            
            <div style={{ display: "flex"}}>
                <RightSidebar />
            </div>
            </div>
        {showLine && <hr style={{ border: "none", height: "1px", background: "rgba(128, 128, 128, 0.2)" }} />}
    </div>);
}

export default Header;