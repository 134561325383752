import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getBlogs, getCategories, getBlogsByCategory } from '../../strapiService';
import './blogs.css';
import { Link } from 'react-router-dom';
import AtomicSpinner from 'atomic-spinner';
import { useStateValue } from '../../statemanagement/StateProvider';
import CategoryIcon from '@mui/icons-material/Category';

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  const [{ language }] = useStateValue();
  const { categorySlug } = useParams(); 
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        setLoading(true);
        let response;
        if (categorySlug) {
          response = await getBlogsByCategory(categorySlug, language); 
        } else {
          response = await getBlogs(language);
        }
        if (response) {
          setBlogs(response);
        } else {
          console.error('Invalid response structure', response);
        }
      } catch (error) {
        console.error('Error fetching blogs:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await getCategories(language);
        if (response) {
          const categoryNames = response.map((category) => ({
            name: category.attributes.name,
            slug: category.attributes.slug,
          }));
          setCategories(categoryNames);
        } else {
          console.error('Invalid response structure', response);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchBlogs();
    fetchCategories();
  }, [language, categorySlug]);

  useEffect(() => {
    if (!categorySlug) return;
  
    const actualSlug = categorySlug.replace(/^(en-|hi-|te-)/, '');
    const expectedSlug = `${language}-${actualSlug}`;
    if (categorySlug !== expectedSlug) {
      navigate(`/astrology-blogs/category/${expectedSlug}`, { replace: true });
    }
  }, [language, categorySlug, navigate]);

  return (
    <div className="blogs-page">
      <div className="sidebar">
        <h2><CategoryIcon /> Categories</h2>
        <ul className="categories-list">
          <li>
            <Link to="/blogs" className={`category-item ${!categorySlug ? 'active' : ''}`}>
              Home
            </Link>
          </li>
          {categories.map((category, index) => (
            <li key={index}>
              <Link
                to={`/astrology-blogs/category/${category.slug}`}
                className={`category-item ${categorySlug === category.slug ? 'active' : ''}`}
              >
                {category.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <div className="main-content">
        <main className="content">
          {loading ? (
            <div className="spinner">
              <AtomicSpinner />
            </div>
            ) : blogs.length > 0 ? (
            <div className="blog-list">
              {blogs.map((blog) => {
                const blogThumbnailData = blog.attributes.thumbnail?.data;
                const blogThumbnailUrl = blogThumbnailData && blogThumbnailData.length > 0
                  ? blogThumbnailData[0].attributes.url
                  : '';

                return (
                  <Link key={blog.id} to={`/blog/${blog.attributes.path}`} className="blog-item">
                    <div className="blog-image-container">
                      {blogThumbnailUrl && (
                        <img
                          src={blogThumbnailUrl}
                          alt={blog.attributes.title}
                          className="blog-image"
                        />
                      )}
                    </div>
                    <div className="blog-details">
                      <h2 className="blog-title">{blog.attributes.title}</h2>
                      <div className="blog-meta">
                        <span className="blog-author">Vandana</span>
                        <span className="blog-date">{new Date(blog.attributes.createdAt).toLocaleDateString()}</span>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          ) : (
            <div className="no-blogs-message">No blogs available for this category.</div>
          )}
        </main>
      </div>
    </div>
    );
};

export default Blogs;
