import React, { useEffect, useState } from 'react';
import "./BasicChat.css";
import { useStateValue } from '../statemanagement/StateProvider';
import UseBirthDetails from './UseBirthDetails';
import axios from "axios";
import { actionTypes } from '../statemanagement/reducer';
import { getUrl } from '../helpers/envUrls';
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import KundliCard from './KundliCard';

const KundliMatchingView = () => {

    UseBirthDetails();
    const [{ dob, tob, city, horoscope }, dispatch] = useStateValue();
    const [entities, setEntities] = useState([]);
    const [loading, setLoading] = useState(false);
    //[userEmail, setUserEmail]

    useEffect(() => {
        dispatch({ type: actionTypes.RESET_STATE });
    }, [])

    useEffect(() => {

        const getEntities = async () => {
            const entityJSON = await axios.get(`${getUrl()}/entities`);
            setEntities(entityJSON.data);
        }
        getEntities();
    }, []);

    const KundliHeader = () => {
        return (
            <div style={{ textAlign: "center" }}>
                <p style={{ marginTop: "8%", marginBottom: "2%", fontSize: "1.2rem", fontWeight: "400", lineHeight: "2.1rem", display: "inline-block", color: "gray" }}>Personalized Predictions & Reports</p>
            </div>
        )
    };

    const PaymentLoading = () => {
        return (<SkeletonTheme baseColor="rgba(128, 128, 128, 0.2)" >
            <p>
                <Skeleton count={10} />
            </p>
        </SkeletonTheme>
        )
    }

    return (
        <div>
            <KundliHeader />

            <br />
            <div style={{ marginTop: "0%" }}>
                <div style={{ textAlign: "center" }}>

                    {loading && <PaymentLoading />}

                    {!loading &&
                        <><KundliCard entityImageSrc={"https://astrosky.blob.core.windows.net/astrosky/Arun.jpg"}
                                entityName={"Kundli Matching Report"}
                                sampleReport={"/Kundli.pdf"}
                                reportType={"Kundli Matching"}
                                entityDescription={"Kundli Matching Report, Top Rated"}
                                entityRating={5} ppm={3}
                                loading={loading}
                                setLoading={setLoading}
                                entityCode={"sunny"}
                                samepleQuestions={"Customized by expert Astrologers, 16-Pages Detailed Report, Based on Vedic Principles, Ashtkoot Guna Milan, Manglik Dosha Analysis, Physical Compatibility, Mental Compatibility, Financial Stability, Character & Affairs, Children"}
                            />
                            <br /> <br />
                        </>}
                </div>
            </div>
        </div>
    );
};

export default KundliMatchingView;
