import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Paper, Box } from '@mui/material';

// Function to calculate the Numerology Career Number based on DOB
const calculateNumerologyCareerNumber = (dob) => {
  const dateParts = dob.split('-'); // Expecting format YYYY-MM-DD
  const year = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10);
  const day = parseInt(dateParts[2], 10);

  let sum = year + month + day;

  while (sum > 9) {
    sum = sum.toString().split('').reduce((acc, digit) => acc + parseInt(digit, 10), 0);
  }

  return sum;
};

// Function to get career options based on the numerology number
const getCareerOptions = (number) => {
    const careerOptions = {
      1: `Leadership roles such as CEO, Manager, or Entrepreneur.
          You excel in positions where you can lead teams, make strategic decisions, and drive projects forward.
          Ideal for those who are innovative, ambitious, and take charge of their responsibilities.`,
      2: `Diplomatic roles such as Counselor, Mediator, or HR Specialist.
          You are well-suited for roles that require tact, patience, and a strong ability to understand and manage interpersonal relationships.
          Ideal for those who are empathetic, collaborative, and excel in resolving conflicts.`,
      3: `Creative professions such as Writer, Artist, or Actor.
          You thrive in careers that allow you to express your creativity, imagination, and communicate your ideas effectively.
          Ideal for those who are artistic, expressive, and have a flair for innovative thinking.`,
      4: `Technical jobs such as Engineer, Architect, or Accountant.
          You are best suited for roles that require precision, structure, and analytical skills. 
          Ideal for those who are detail-oriented, methodical, and enjoy solving complex problems.`,
      5: `Dynamic roles like Salesperson, Travel Guide, or Public Relations Specialist.
          You excel in fast-paced environments where adaptability, communication skills, and enthusiasm are key.
          Ideal for those who are outgoing, versatile, and thrive in ever-changing situations.`,
      6: `Service-oriented careers such as Nurse, Teacher, or Social Worker.
          You are drawn to roles that involve helping others and making a positive impact on people's lives.
          Ideal for those who are compassionate, responsible, and dedicated to supporting and nurturing others.`,
      7: `Analytical roles like Researcher, Scientist, or Philosopher.
          You are best suited for careers that require deep thinking, investigation, and a quest for knowledge.
          Ideal for those who are introspective, analytical, and have a strong interest in understanding the underlying truths of complex issues.`,
      8: `Management positions including Business Executive, Financial Analyst, or Director.
          You excel in high-level positions that involve overseeing operations, making strategic decisions, and achieving financial goals.
          Ideal for those who are organized, strategic, and have strong leadership qualities.`,
      9: `Humanitarian careers such as Nonprofit Organizer, Activist, or Therapist.
          You are driven by a desire to make the world a better place and help those in need.
          Ideal for those who are idealistic, empathetic, and committed to causes that promote social justice and personal growth.`
    };
  
    return careerOptions[number] || "Career options not found.";
  };

const CareerCalculator = () => {
  const [name, setName] = useState('');
  const [dob, setDob] = useState('');
  const [result, setResult] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    const careerNumber = calculateNumerologyCareerNumber(dob);
    const careerOptions = getCareerOptions(careerNumber);

    setResult(`Hi ${name}, based on your numerology career number (${careerNumber}), here are some career options that might suit you: ${careerOptions}`);
  };

  return (
    <Container
      sx={{
        background: 'linear-gradient(to right, #ffeb3b, #e3f2fd)',
        borderRadius: '10px',
        padding: 4,
        boxShadow: 3,
        maxWidth: '500px',
        margin: 'auto',
        marginTop: 5
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', color: '#fff', marginBottom: 2 }}>
          Numerology Career Calculator
        </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Full Name"
          variant="outlined"
          margin="normal"
          InputLabelProps={{ shrink: true }}
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          fullWidth
          label="Date of Birth (DD-MM-YYYY)"
          variant="outlined"
          margin="normal"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={dob}
          onChange={(e) => setDob(e.target.value)}
          required
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          sx={{ mt: 2 }}
        >
          Calculate Career Options
        </Button>
      </form>
      {result && (
        <Box
          sx={{
            padding: 3,
            marginTop: 3,
            backgroundColor: '#fff',
            color: '#003c8f',
            boxShadow: 2
          }}
          component={Paper}
        >
          <Typography variant="body1">{result}</Typography>
        </Box>
      )}
    </Container>
  );
};

export default CareerCalculator;
