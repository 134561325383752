import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Paper, Box, Grid } from '@mui/material';
import { pink } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';

const letterValue = (char) => {
  return char.toLowerCase().charCodeAt(0) - 96; // A=1, B=2, ..., Z=26
};

const calculateNumerology = (name1, name2) => {
  const combinedNames = name1 + name2;
  let score = 0;

  for (let char of combinedNames) {
    if (/[a-zA-Z]/.test(char)) { // Check if it's a letter
      score += letterValue(char);
    }
  }

  // Reduce to a single digit
  while (score > 9) {
    score = score.toString().split('').reduce((sum, digit) => sum + Number(digit), 0);
  }

  return score;
};

const getInterpretation = (score) => {
  switch (score) {
    case 1: return "Independent Lovers: You both thrive on your individuality.";
    case 2: return "Peacemakers: You balance each other out beautifully.";
    case 3: return "Creative Spirits: Your relationship is filled with creativity and joy.";
    case 4: return "Stable Foundation: You build a strong and reliable partnership.";
    case 5: return "Adventurous Souls: You both love excitement and new experiences.";
    case 6: return "Nurturers: You provide care and support for each other.";
    case 7: return "Deep Thinkers: You share a strong mental connection.";
    case 8: return "Powerful Alliance: Together, you achieve great things.";
    case 9: return "Universal Love: Your love transcends boundaries and expectations.";
    default: return "A unique combination that brings its own special energy!";
  }
};

const LoveCalculator = () => {
  const [value1, setValue1] = useState('');
  const [value2, setValue2] = useState('');
  const [loveScore, setLoveScore] = useState(null);
  const [lovePercentage, setLovePercentage] = useState(null);
  const [error, setError] = useState('');

  const handleCalculate = () => {
    // Clear previous error message
    setError('');

    // Check for empty fields
    if (!value1.trim() || !value2.trim()) {
      setError('Both fields are required!');
      return;
    }

    const score = calculateNumerology(value1, value2);
    setLoveScore(score);
    setLovePercentage((score * 11.11).toFixed(1)); 
  };

  return (
    <Container component="main" maxWidth="sm" sx={{ marginTop: 8 }}>
      <Paper elevation={6} sx={{ padding: 4, borderRadius: 2, background: 'linear-gradient(135deg, #ffd1dc, #ff8da1)', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)' }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', color: '#fff', marginBottom: 2 }}>
          Find Your Love %
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={5}>
              <Typography variant="h7" sx={{ color: 'black', textAlign: 'start' }}>
                Your Name
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                value={value1}
                onChange={(e) => setValue1(e.target.value)}
                sx={{ backgroundColor: 'white' }}
              />
            </Grid>
            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center' }}>
              <FavoriteIcon sx={{ fontSize: 40, color: pink[500] }} />
            </Grid>
            <Grid item xs={5}>
              <Typography variant="h7" sx={{ color: 'black', textAlign: 'start'}}>
                Partner's Name
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                value={value2}
                onChange={(e) => setValue2(e.target.value)}
                sx={{ backgroundColor: 'white' }}
              />
            </Grid>
          </Grid>
          {error && (
            <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>
              {error}
            </Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleCalculate}
            sx={{ marginTop: 3, display: 'block', width: '100%' }}
          >
            Calculate
          </Button>
          {loveScore !== null && !error && (
            <Box sx={{ textAlign: 'center', marginTop: 2, color: '#a94064' }}>
              <Typography variant="h6" component="p">
                Your love numerology score is {loveScore}!
              </Typography>
              <Typography variant="h6" component="p">
                This translates to a love percentage of {lovePercentage}%!
              </Typography>
              <Typography variant="body1" component="p">
                {getInterpretation(loveScore)}
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default LoveCalculator;

