import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Paper, Box } from '@mui/material';

// Helper function to calculate Life Path Number
const sum = (str) => str.split('').reduce((acc, digit) => acc + parseInt(digit, 10), 0);
const reduceToSingleDigit = (num) => {
    while (num > 9) {
      num = sum(num.toString());
    }
    return num;
  };

const calculateLifePathNumber = (dob) => {
  const dateParts = dob.split('-');
  const year = dateParts[0];
  const month = dateParts[1];
  const day = dateParts[2];

  const lifePathNumber = reduceToSingleDigit(sum(year) + sum(month) + sum(day));
  return lifePathNumber;
};

// Helper function to calculate Personal Year Number
const calculatePersonalYearNumber = (dob, currentYear) => {
  const lifePathNumber = calculateLifePathNumber(dob);
  const yearSum = sum(currentYear.toString());
  return reduceToSingleDigit(lifePathNumber + yearSum);
};

// Helper function to find lucky dates in current month
const findLuckyDates = (lifePathNumber, personalYearNumber, currentMonth) => {
  const luckyDates = [];
  const numDays = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDate();

  const isLuckyDate = (date) => {
    const sumDigits = (date) => date.split('').reduce((acc, digit) => acc + parseInt(digit, 10), 0);
    const reduceToSingleDigit = (num) => {
      while (num > 9) {
        num = sumDigits(num.toString());
      }
      return num;
    };
    const dateNumber = reduceToSingleDigit(sumDigits(date));
    return dateNumber === lifePathNumber || dateNumber === personalYearNumber;
  };

  for (let day = 1; day <= numDays; day++) {
    const dateStr = day.toString().padStart(2, '0'); // Ensure two digits
    if (isLuckyDate(dateStr)) {
      luckyDates.push(dateStr);
    }
  }

  return luckyDates;
};

const LuckyDateCalculator = () => {
  const [dob, setDob] = useState('');
  const [result, setResult] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date();

    const lifePathNumber = calculateLifePathNumber(dob);
    const personalYearNumber = calculatePersonalYearNumber(dob, currentYear);
    const luckyDates = findLuckyDates(lifePathNumber, personalYearNumber, currentMonth);

    setResult(
        `<div>According to Vedic Numerology,</div>
        <br>
        <div>Life Path Number: ${lifePathNumber}</div>
      <div>Personal Year Number: ${personalYearNumber}</div>
      <div><strong style="font-size: 16px; color: black;">Lucky Dates in ${currentMonth.toLocaleString('default', { month: 'long' })}:</strong> ${luckyDates.join(', ')}</div>`
    );
  };

  return (
    <Container
      sx={{
        background: 'linear-gradient(to right, #ffeb3b, #e3f2fd)',
        borderRadius: '10px',
        padding: 4,
        boxShadow: 3,
        maxWidth: '500px',
        margin: 'auto',
        marginTop: 5
      }}
    >
        <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', color: '#fff', marginBottom: 2 }}>
          Lucky Dates Calculator
        </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Date of Birth (DD-MM-YYYY)"
          variant="outlined"
          margin="normal"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={dob}
          onChange={(e) => setDob(e.target.value)}
          required
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          sx={{ mt: 2 }}
        >
          Calculate Lucky Dates
        </Button>
      </form>
      {result && (
        <Box
          sx={{
            padding: 3,
            marginTop: 3,
            backgroundColor: '#fff',
            color: '#003c8f',
            boxShadow: 2
          }}
          component={Paper}
          dangerouslySetInnerHTML={{ __html: result }}
        />
      )}
    </Container>
  );
};

export default LuckyDateCalculator;
