// import * as React from 'react';
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
// import FormLabel from '@mui/material/FormLabel';
// import Box from '@mui/material/Box';
// import { styled } from '@mui/material/styles';
// import { actionTypes } from '../statemanagement/reducer';

// const BlackRadio = styled(Radio)(({ theme }) => ({
//   color: 'black',
//   '&.Mui-checked': {
//     color: 'black',
//   },
// }));

// export default function RowRadioButtonsGroup({gender, dispatch}) {
//   return (
//     <FormControl>
//       <Box display="flex" alignItems="center">
//         <FormLabel style={{ fontWeight: '500', color: 'black' }}>Gender</FormLabel>
//         <RadioGroup
//           row
//           aria-labelledby="demo-row-radio-buttons-group-label"
//           name="row-radio-buttons-group"
//           value={gender}
//           onChange={(event)=>{
//             //dispatch
//             console.log(event.target.value);
//             //store in cache
//             localStorage.setItem("gender", event.target.value);
//             console.log(`SETTING GENDER TO: ${event.target.value}`);
//             dispatch({type: actionTypes.SET_GENDER, gender: event.target.value});
//           }}
//           style={{ marginLeft: '15px', color: 'black' }} // Adjust the margin as needed
//         >
//           <FormControlLabel value="male" control={<BlackRadio />} label="Male" />
//           <FormControlLabel value="female" control={<BlackRadio />} label="Female" />
//         </RadioGroup>
//       </Box>
//     </FormControl>
//   );
// }


import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { actionTypes } from '../statemanagement/reducer';
import { useStateValue } from '../statemanagement/StateProvider';

const BlackRadio = styled(Radio)(({ theme }) => ({

  color: 'black',
  '&.Mui-checked': {
    color: 'black',
  },
}));

export default function RowRadioButtonsGroup({ gender, dispatch }) {
  return (
    <FormControl style={{marginTop:"5px", marginBottom:"5px"}}>
      <Box display="flex" alignItems="center">
        <FormLabel style={{ fontWeight: '500', color: 'black', fontSize:"large" }}>Gender</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={gender}
          onChange={(event) => {
            //dispatch
            console.log(event.target.value);
            //store in cache
            console.log(`SETTING GENDER TO: ${event.target.value}`);
            dispatch({ type: actionTypes.SET_GENDER, gender: event.target.value });
          }}
          style={{ marginLeft: '18px', color: 'black' }} // Adjust the margin as needed
        >
          <FormControlLabel
            value="male"
            control={<BlackRadio />}
            label="Male"
            componentsProps={{ typography: { style: { marginLeft: '-3px' } } }} // Adjust the margin as needed
          />
          <FormControlLabel
            value="female"
            control={<BlackRadio />}
            label="Female"
            componentsProps={{ typography: { style: { marginLeft: '-3px' } } }} // Adjust the margin as needed
          />
        </RadioGroup>
      </Box>
    </FormControl>
  );
}
