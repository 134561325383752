import React, { useState, useRef } from 'react'
import "./AskFollowUp.css";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArticleIcon from '@mui/icons-material/Article';
import AtomicSpinner from 'atomic-spinner';
import { useNavigate } from "react-router-dom"
import { Button } from '@mui/material';
import { useStateValue } from "../statemanagement/StateProvider"
import { actionTypes } from '../statemanagement/reducer';
import { submitCustomEvent } from "../helper/customevents";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { RefreshOutlined } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { getUrl } from '../helpers/envUrls';
import axios from 'axios';
import { saveUserQuestion } from '../pages/StoreData';
import mixpanelService from '../mixpanelService';

function AskFollowUp({ showQuestions = false }) {

  const [{ followup, conversationHistory, loading, streaming, nextQuestion, nextQuestionLoading, userCred, entity, gender, app_category }, dispatch] = useStateValue();
  const [input, setInput] = useState(followup);
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const askfollowup = (e) => {

    if (loading || streaming)
      return;
    if (!!e)
      e.preventDefault();
    if (input === "")
      return;

    if (inputRef.current) {
      inputRef.current.blur();
    }

    dispatch({ type: actionTypes.SET_FOLLOWUP_TERM, followup: input });
   
    window.gtag('event', entity);
    mixpanelService.trackEvent(`Follow up Query`,
      {
        entity: entity,
        input_typed: input,
        auto_gen: false,
        logged_in: userCred ? true: false
      }
    )
    //console.log("Send question to DB");
    //saveUserQuestion(input, entity);
    //console.log("Next");
    
    setInput("");
    //alert("sending question to analytics")
    submitCustomEvent('Refine_Input', input);

    // window.gtag('event','Question_Asked');
  }

  const isStart = () => conversationHistory.filter(u => u.role == "user").length == 0;

  const fetch = async () => {
    dispatch({ type: actionTypes.SET_NEXT_Q_LOADING, nextQuestionLoading: true });
    
    try {
      const response = await axios.post(`${getUrl()}/newquestion`, { 
        messages: conversationHistory, 
        entity: entity, 
        gender: gender 
      });
  
      if (response.data && response.data !== "") {
        dispatch({
          type: actionTypes.SET_NEXT_Q,
          next: response.data.includes(":") ? response.data.split(":")[1].trim() : response.data
        });
      }
    } catch (error) {
      console.error("Error fetching next question:", error);
    } finally {
      dispatch({ type: actionTypes.SET_NEXT_Q_LOADING, nextQuestionLoading: false });
    }
  };
  

  return (
    <div>
      <form className='askfollowup' style={{ position: "fixed", bottom: "10px", background: "white", zIndex: 1000, textAlign: "center" }} onSubmit={askfollowup}>
        {/* old <div style={{ display: "flex", justifyContent: "center" }}>
            <div className='svg-container' style={{ display: "flex", justifyContent: "center" }}>
              {nextQuestionLoading && showQuestions && <AtomicSpinner />}
            </div>
              {nextQuestion != "" && showQuestions && <Button className='auto__gen__button' onClick={(_) => {
                dispatch({ type: actionTypes.SET_FOLLOWUP_TERM, followup: nextQuestion });
                window.gtag('event', 'auto_gen', {
                  event_category: 'auto_gen',
                  event_label: nextQuestion,
                });
                window.gtag('event', entity);

              }}>{nextQuestion}</Button>}
              
        </div> */}


    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
      <div style={{ flex: 1, display: "flex", justifyContent: "center", width:"90%" }}>
        {nextQuestionLoading && showQuestions && (
          <div className='svg-container' style={{marginLeft:"10%"}}>
            <AtomicSpinner />
          </div>
        )}
        {nextQuestion !== "" && showQuestions  && (
          <Button
            className='auto__gen__button'
            onClick={(_) => {
              dispatch({ type: actionTypes.SET_FOLLOWUP_TERM, followup: nextQuestion });
              window.gtag('event', 'auto_gen', {
                event_category: 'auto_gen',
                event_label: nextQuestion,
              });
              window.gtag('event', entity);
              mixpanelService.trackEvent(`Follow up Query`,
                {
                  entity: entity,
                  input_typed: false,
                  auto_gen: nextQuestion,
                  logged_in: userCred ? true : false,
                }
              )
            }}
          >
            {nextQuestion}
          </Button>
        )}
      </div>
      
      {/* <div style={{ flex: "0 0 10%", display: "flex", justifyContent: "flex-end", width:"12%" }}> */}
      <div style={{ flex: "0 0 10%", display: "flex", justifyContent: "center", width:"12%" }}>
        {nextQuestion !== "" && showQuestions && (
          // <Button
          //   startIcon={<RefreshIcon sx={{ color: "rgba(0,0,0,0.5)" }} />}
          //   style={{ display: "block" }}
          //   onClick={async () => {
          //     window.gtag('event', "refresh_q");
          //     dispatch({ type: actionTypes.SET_NEXT_Q, next: "" });
          //     await fetch(); // Call the fetch function directly
          //   }}
          // />
          <img src='/icons/reload.png' onClick={async (_)=>{
              window.gtag('event', "refresh_q");
              mixpanelService.trackEvent('Clicked Reload',{
                pre_reload_ques: nextQuestion,
                logged_in: userCred ? true: false
              });
              dispatch({ type: actionTypes.SET_NEXT_Q, next: "" });
              await fetch();
          }} style={{width:"24px"}}/>
        )}
      </div>
    </div>




        <div className='askfollowup__input'>
          <input ref={inputRef} value={input} onChange={e => setInput(e.target.value)} placeholder="Message Astrologer .." />
          {input.length == 0 && <ArrowCircleRightIcon style={{ opacity: 0.5, fontSize: "2rem" }} onClick={askfollowup} />}
          {input.length > 0 && <ArrowCircleRightIcon style={{ opacity: 1, fontSize: "2rem" }} onClick={askfollowup} />}

        </div>
        {/* <p style={{fontSize:"0.7rem", opacity:0.5}}><i>100% Anonymous & Secure chat</i></p> */}

        <Button style={{ display: "none" }} type='submit' onClick={askfollowup} />
        <div className='refine_button_group' style={{ minWidth: "80vw !important", maxHeight: "14vh", display: "none" }}>

          <div className='refine_button_row'>

            <Button className='refine_button' startIcon={<ArticleIcon sx={{ fontSize: "25px !important" }} />} onClick={(_) => {
              if (streaming || isStart(conversationHistory)) { return; } window.gtag('event', entity); dispatch({ type: actionTypes.SET_FOLLOWUP_TERM, followup: "Add more detail" }); window.gtag('event', 'Refine_PA', {
                event_category: 'Refine_PA',
                event_label: "More Detail",
              });
            }}>Detail</Button>

            <Button className='refine_button' startIcon={<CalendarMonthIcon sx={{ fontSize: "25px !important" }} />} onClick={(_) => {
              dispatch({ type: actionTypes.RESET_STATE });
              navigate("/edit");
            }}>Edit</Button>

            <Button className='refine_button' startIcon={<img src="/icons/horoscope.png" width={"25px"} style={{ paddingRight: "2%" }} />} onClick={(_) => {
              window.gtag('event', 'Refine_PA', {
                event_category: 'Refine_PA',
                event_label: "Back",
              });
              navigate("/discover");
            }}>New Chat</Button>

          </div>
        </div>


      </form>

    </div>
  )
}

export default AskFollowUp