import React, { useState } from 'react';
import {
  Button,
  Rating,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import { useStateValue } from '../statemanagement/StateProvider';
import { actionTypes } from '../statemanagement/reducer';
import { useNavigate } from 'react-router-dom';
import './Entity.css';
import SuccessPopup from './SuccessPopup';
import { load } from "@cashfreepayments/cashfree-js";
import axios from "axios";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import useWindowSize from './useWindowSize';
import QuestionsDisplay from './QuestionsDisplay';
import KundliForm from './Forms/KundliForm';

const ReportCard = ({
  entityName,
  entityCode,
  entityDescription,
  entityImageSrc,
  entityRating,
  samepleQuestions,
  reportType,
  chatDisplay = 'Chat',
  showSample = true,
  sampleReport,
  loading,
  setLoading
}) => {

  const [{ userCred, entity, dob, tob, city, horoscope }, dispatch] = useStateValue();
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userDob, setUserDob] = useState(dob || localStorage.getItem("dob"));
  const [userTob, setUserTob] = useState(tob || localStorage.getItem("tob"));
  const [userPlace, setUserPlace] = useState(city || localStorage.getItem("city"));

  const [userContact, setUserContact] = useState('');
  const [errors, setErrors] = useState({});
  const [openPopup, setOpenPopup] = useState(false);

  const [price, setPrice] = useState(99);

  //Init SDK
  let cashfree;
  let initializeSDK = async () => {
    cashfree = await load({
      mode: "production"
    })
  }

  initializeSDK();

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const validateForm = () => {
    const newErrors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const dobPattern = /^\d{2}\/\d{2}\/\d{4}$/; // DD/MM/YYYY
    const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/; // HH:MM 24-hour format
    const contactPattern = /^\+?[1-9]\d{1,14}$/; // E.164 format

    if (!emailPattern.test(userEmail)) {
      newErrors.email = 'Please enter a valid email address.';
    }
    if (!dobPattern.test(userDob)) {
      newErrors.dob = 'Date of Birth must be in DD/MM/YYYY format.';
    }
    if (!timePattern.test(userTob)) {
      newErrors.tob = 'Time of Birth must be in HH:MM (24-hour) format.';
    }
    if (!contactPattern.test(userContact)) {
      newErrors.contact = 'Please enter a valid contact number.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  //when user clicks proceed to pay 
  const handleConfirm = async () => {
    //auto-scroll to top


    if (!validateForm()) return;

    handleDialogClose();

    window.scrollTo({
      top: 0,
      behavior: 'smooth' // You can use 'auto' for an instant scroll
    });

    setLoading(true);

    try {

      //Email to User
      const user_data = {
        //  service_id: 'service_wghif34',//service_o4dwoka
        service_id: 'service_o4dwoka',
        template_id: 'template_yb48m8t', //template_id: 'template_zl6s7xp' for internal remarketing
        // template_id: 'template_d1ikp7j' for sending payment link to user 
        user_id: 'E4kX0L8By_ayvUhv7',   //TODO: Set emailJS user_email to 'support@astrosky.co.in'
        template_params: {
          userName,
          userEmail,
          userDob,
          userTob,
          userPlace,
          userContact,
          reportType
        }
      };

      const user_response = await fetch('https://api.emailjs.com/api/v1.0/email/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(user_data)
      });
      console.log('Email sent successfully');

      console.log(JSON.stringify(user_response));

      dispatch({ type: actionTypes.SET_LOADING, loading: true });


      const localHost = `http://localhost:8089`;
      const prodHost = `https://paymentgatewayservice1964.azurewebsites.net`;

      const orderResponse = await axios.post(`${prodHost}/createOrder`, {
        "phone": userContact.replace("+91", ""),
        "reportType": reportType
        // "phone": "9315282408"
      });

      const session_id = orderResponse.data.payment_session_id;
      const order_id = orderResponse.data.order_id;

      localStorage.setItem("order_id", order_id);

      cashfree.checkout({
        paymentSessionId: session_id
      }).then((res) => {

      });

    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  const handleClosePopup = async () => {
    setOpenPopup(false);

  };

  const skill = entityDescription.split(',').map((entitySkill) => {
    const trimmedSkill = entitySkill.trim();
    const isHighlighted =
      trimmedSkill === 'Powered by AI' ||
      trimmedSkill === '🇮🇳 Discount' ||
      trimmedSkill === 'Raksha Bandhan Offer' ||
      trimmedSkill === 'Top Rated' ||
      trimmedSkill === 'Best Seller' ||
      trimmedSkill === 'Wealth Horoscope' ||
      trimmedSkill === 'Personality' ||
      trimmedSkill === 'Love' ||
      trimmedSkill === 'General' ||
      trimmedSkill === 'Career' ||
      trimmedSkill === 'Motivational Coach';
    return (
      <p
        key={trimmedSkill}
        style={{
          background: isHighlighted ? 'black' : 'transparent',
          color: isHighlighted ? 'white' : 'inherit',
          opacity: isHighlighted ? '0.8' : '1',
          fontSize: "0.9rem"
        }}
        className="skillStyle"
      >
        {entitySkill}
      </p>
    );
  });

  const { width, height } = useWindowSize();
  const [showConfetti, setShowConfetti] = useState(false);

  return (

    <>

      {!loading &&

        <div>
          <img
            onClick={handleDialogOpen}
            className="entityImage"
            src={entityImageSrc}
            style={{ borderRadius: '20px', width: '80%' }}
            alt={entityName}
          />
          <br />
          <br />

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>{skill}</div>

          {((reportType == "Love") || (reportType == "Passion")) && <Button className="discoverChatStyleLove" onClick={handleDialogOpen}>{entityName}  <s style={{ margin: "5px" }}>₹999</s>  (₹499)</Button>}
          {reportType == "Career" && <Button className="discoverChatStyle2" onClick={handleDialogOpen}>{entityName}  <s style={{ margin: "5px" }}>₹999</s>  (₹499)</Button>}
          <br />
          <br />

          <QuestionsDisplay sampleQuestions={samepleQuestions} />
          <br />

          <h3 style={{ fontWeight: "500" }}>Sample Report</h3>
          <br />

          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            <div style={{
              height: '500px',
              width: "100%",
              margin: "none !important"
            }}>
              <Viewer fileUrl={`${sampleReport}`} />
            </div>
          </Worker>
          <br />  <br />

          {((reportType == "Love") || (reportType == "Passion")) && <Button className="discoverChatStyleLove" onClick={handleDialogOpen}>{entityName}  <s style={{ margin: "5px" }}>₹399</s>  (₹199)</Button>}
          {reportType == "Career" && <Button className="discoverChatStyle2" onClick={handleDialogOpen}>{entityName}  <s style={{ margin: "5px" }}>₹399</s>  (₹199)</Button>}

          <br /> <br />
          {showSample && <Button className="discoverChatStyleSample" onClick={(e) => window.open(`https://astrosky.blob.core.windows.net/astrosky${sampleReport}`, '_blank')}>
            Download Sample Report
          </Button>}
          <KundliForm dialogOpen={dialogOpen} handleDialogClose={handleDialogClose} setUserName={setUserName} setUserEmail={setUserEmail} setUserContact={setUserContact} setUserDob={setUserDob} setUserTob={setUserTob} setUserPlace={setUserPlace} handleConfirm={handleConfirm}
            userName={userName} userEmail={userEmail} userContact={userContact} userDob={userDob} userTob={userTob} userPlace={userPlace} errors={errors} />
            
          {/* <Dialog open={dialogOpen} onClose={handleDialogClose}>
            <DialogTitle>Confirm Your Details</DialogTitle>
            <DialogContent>
              <p style={{ fontFamily: 'Roboto', margin: "5px" }}><strong>Report will be sent to you (via Email) in 24 hours</strong></p>
              <TextField
                autoFocus
                margin="dense"
                label="Your Name"
                type="text"
                fullWidth
                variant="standard"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                error={!!errors.name}
                helperText={errors.name}
              />
              <TextField
                margin="dense"
                label="Email ID"
                type="email"
                fullWidth
                variant="standard"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                error={!!errors.email}
                helperText={errors.email}
              />
              <TextField
                margin="dense"
                label="Contact Number"
                type="text"
                fullWidth
                variant="standard"
                value={userContact}
                onChange={(e) => setUserContact
                  (e.target.value)}
                error={!!errors.contact}
                helperText={errors.contact}
              />
              <TextField
                margin="dense"
                label="Date of Birth"
                type="text"
                fullWidth
                variant="standard"
                value={userDob}
                onChange={(e) => setUserDob(e.target.value)}
                error={!!errors.dob}
                helperText={errors.dob}
              />
              <TextField
                margin="dense"
                label="Time of Birth (24-hour format, e.g. 16:00)"
                type="text"
                fullWidth
                variant="standard"
                value={userTob}
                onChange={(e) => setUserTob(e.target.value)}
                error={!!errors.tob}
                helperText={errors.tob}
              />
              <TextField
                margin="dense"
                label="Place of Birth"
                type="text"
                fullWidth
                variant="standard"
                value={userPlace}
                onChange={(e) => setUserPlace(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button endIcon={<ShoppingCartCheckoutIcon sx={{ fontSize: "23px !important" }} />} className="discoverChatStyle3" onClick={handleConfirm}>Place Order</Button>
              <br /> <br />
            </DialogActions>
          </Dialog> */}
          <SuccessPopup
            open={openPopup}
            onClose={handleClosePopup}
          />
        </div>}
    </>
  );
};

export default ReportCard;