import AppFooter from '../../pages/AppFooter';
import { useMediaQuery } from '@mui/material';
import './webFooter.css';

const WebFooter = () => {
  const isMobile = useMediaQuery('(max-width:1039px)');
  
  return isMobile ? (
    <AppFooter />
  ) : (
    <div className="web-footer">
      <footer className="footer">
        <div className="footer-content">
          <a href="/about-us">About Us</a>
          <a href="/contact-us">Contact Us</a>
          <a href="/cancellation">Refund & Cancellation Policy</a>
          <a href="/privacy">Privacy Policy</a>
          <a href="/terms-of-service">Terms & Conditions</a>
        </div>
      </footer>
    </div>
  );
};

export default WebFooter;
