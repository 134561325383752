import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@mui/material';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';

const KundliForm = ({dialogOpen, handleDialogClose, 
    setUserEmail, setUserContact, handleConfirm,
    userEmail, userContact, errors,
    setBoyDob,
    setBoyTob,
    setBoyPlace,
    setGirlDob,
    setGirlTob,
    setGirlPlace,
    boyDob,
    boyTob,
    boyPlace, 
    girlDob,
    girlTob,
    girlPlace
}) => {

    return (<Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Confirm Your Details</DialogTitle>
        <DialogContent>
          {/* <p style={{ fontFamily: 'Roboto', margin: "5px" }}><strong>Report will be sent to you (via Email) in 24 hours</strong></p> */}
          <TextField
            margin="dense"
            label="Email ID"
            type="email"
            fullWidth
            variant="standard"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            margin="dense"
            label="Contact Number"
            type="text"
            fullWidth
            variant="standard"
            value={userContact}
            onChange={(e) => setUserContact
              (e.target.value)}
            error={!!errors.contact}
            helperText={errors.contact}
          />

          {/* <TextField
            autoFocus
            margin="dense"
            label="Boy's Name"
            type="text"
            fullWidth
            variant="standard"
            value={boyName}
            onChange={(e) => setBoyName(e.target.value)}
            error={!!errors.name}
            helperText={errors.name}
          /> */}
          
          <TextField
            margin="dense"
            label="Boy's Date of Birth"
            type="text"
            fullWidth
            variant="standard"
            value={boyDob}
            onChange={(e) => setBoyDob(e.target.value)}
            error={!!errors.dob}
            helperText={errors.dob}
          />
          <TextField
            margin="dense"
            label="Boy's Time of Birth (24-hour format, e.g. 16:00)"
            type="text"
            fullWidth
            variant="standard"
            value={boyTob}
            onChange={(e) => setBoyTob(e.target.value)}
            error={!!errors.tob}
            helperText={errors.tob}
          />
          <TextField
            margin="dense"
            label="Boy's Place of Birth"
            type="text"
            fullWidth
            variant="standard"
            value={boyPlace}
            onChange={(e) => setBoyPlace(e.target.value)}
          />

{/* <TextField
            autoFocus
            margin="dense"
            label="Girl's Name"
            type="text"
            fullWidth
            variant="standard"
            value={boyName}
            onChange={(e) => setBoyName(e.target.value)}
            error={!!errors.name}
            helperText={errors.name}
          /> */}
          
          <TextField
            margin="dense"
            label="Girl's Date of Birth"
            type="text"
            fullWidth
            variant="standard"
            value={girlDob}
            onChange={(e) => setGirlDob(e.target.value)}
            error={!!errors.dob}
            helperText={errors.dob}
          />
          <TextField
            margin="dense"
            label="Girl's Time of Birth (24-hour format, e.g. 16:00)"
            type="text"
            fullWidth
            variant="standard"
            value={girlTob}
            onChange={(e) => setGirlTob(e.target.value)}
            error={!!errors.tob}
            helperText={errors.tob}
          />
          <TextField
            margin="dense"
            label="Girl's Place of Birth"
            type="text"
            fullWidth
            variant="standard"
            value={girlPlace}
            onChange={(e) => setGirlPlace(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button endIcon={<ShoppingCartCheckoutIcon sx={{ fontSize: "23px !important" }} />} className="discoverChatStyle3" onClick={handleConfirm}>Place Order</Button>
          <br /> <br />
        </DialogActions>
      </Dialog>)
}

export default KundliForm;