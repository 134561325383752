import React, { useState } from 'react';
import {
  Button,
} from '@mui/material';
import { useStateValue } from '../statemanagement/StateProvider';
import { actionTypes } from '../statemanagement/reducer';
import { useNavigate } from 'react-router-dom';
import './Entity.css';
import SuccessPopup from './SuccessPopup';
import { load } from "@cashfreepayments/cashfree-js";
import axios from "axios";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import useWindowSize from './useWindowSize';
import QuestionsDisplay from './QuestionsDisplay';
import KundliForm from './Forms/KundliForm';

const KundliCard = ({
  entityName,
  entityCode,
  entityDescription,
  entityImageSrc,
  entityRating,
  samepleQuestions,
  reportType,
  chatDisplay = 'Chat',
  showSample = true,
  sampleReport,
  loading,
  setLoading
}) => {

  const [{ userCred, entity, dob, tob, city, horoscope }, dispatch] = useStateValue();
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(false);
  //const [boyName, setBoyName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [boyDob, setBoyDob] = useState(dob || localStorage.getItem("dob"));
  const [boyTob, setBoyTob] = useState(tob || localStorage.getItem("tob"));
  const [boyPlace, setBoyPlace] = useState(city || localStorage.getItem("city"));

  const [girlDob, setGirlDob] = useState(dob || localStorage.getItem("dob"));
  const [girlTob, setGirlTob] = useState(tob || localStorage.getItem("tob"));
  const [girlPlace, setGirlPlace] = useState(city || localStorage.getItem("city"));

  const [userContact, setUserContact] = useState('');
  const [errors, setErrors] = useState({});
  const [openPopup, setOpenPopup] = useState(false);

  const [price, setPrice] = useState(99);

  //Init SDK
  let cashfree;
  let initializeSDK = async () => {
    cashfree = await load({
      mode: "production"
    })
  }

  initializeSDK();

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const validateForm = () => {
    const newErrors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const dobPattern = /^\d{2}\/\d{2}\/\d{4}$/; // DD/MM/YYYY
    const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/; // HH:MM 24-hour format
    const contactPattern = /^\+?[1-9]\d{1,14}$/; // E.164 format

    if (!emailPattern.test(userEmail)) {
      newErrors.email = 'Please enter a valid email address.';
    }
    if (!dobPattern.test(boyDob) || !dobPattern.test(girlDob)) {
      newErrors.dob = 'Date of Birth must be in DD/MM/YYYY format.';
    }
    if (!timePattern.test(boyTob) || !timePattern.test(girlTob)) {
      newErrors.tob = 'Time of Birth must be in HH:MM (24-hour) format.';
    }
    if (!contactPattern.test(userContact)) {
      newErrors.contact = 'Please enter a valid contact number.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  //when user clicks proceed to pay 
  const handleConfirm = async () => {
    //auto-scroll to top


    if (!validateForm()) return;

    handleDialogClose();

    window.scrollTo({
      top: 0,
      behavior: 'smooth' // You can use 'auto' for an instant scroll
    });

    setLoading(true);

    try {

      //Email to User
      const user_data = {
        //  service_id: 'service_wghif34',//service_o4dwoka
        service_id: 'service_o4dwoka',
        template_id: 'template_nn8cfqk', //template_id: 'template_zl6s7xp' for internal remarketing
        // template_id: 'template_d1ikp7j' for sending payment link to user 
        user_id: 'E4kX0L8By_ayvUhv7',   //TODO: Set emailJS user_email to 'support@astrosky.co.in'
        template_params: {
          // userName,
          userEmail,
          userContact,
          boyDob,
          boyTob,
          boyPlace,
          girlDob,
          girlTob,
          girlPlace,
          reportType
        }
      };

      const user_response = await fetch('https://api.emailjs.com/api/v1.0/email/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(user_data)
      });
      console.log('Email sent successfully');

      console.log(JSON.stringify(user_response));

      dispatch({ type: actionTypes.SET_LOADING, loading: true });


      const localHost = `http://localhost:8089`;
      const prodHost = `https://paymentgatewayservice1964.azurewebsites.net`;

      const orderResponse = await axios.post(`${prodHost}/createOrder`, {
        "phone": userContact.replace("+91", ""),
        "reportType": reportType
        // "phone": "9315282408"
      });

      const session_id = orderResponse.data.payment_session_id;
      const order_id = orderResponse.data.order_id;

      localStorage.setItem("order_id", order_id);

      cashfree.checkout({
        paymentSessionId: session_id
      }).then((res) => {

      });

    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  const handleClosePopup = async () => {
    setOpenPopup(false);

  };

  const skillsDisplay = entityDescription.split(',').map((entitySkill) => {
    const trimmedSkill = entitySkill.trim();
    const isHighlighted =
      trimmedSkill === 'Powered by AI' ||
      trimmedSkill === '🇮🇳 Discount' ||
      trimmedSkill === 'Raksha Bandhan Offer' ||
      trimmedSkill === 'Top Rated' ||
      trimmedSkill === 'Best Seller' ||
      trimmedSkill === 'Wealth Horoscope' ||
      trimmedSkill === 'Personality' ||
      trimmedSkill === 'Love' ||
      trimmedSkill === 'General' ||
      trimmedSkill === 'Career' ||
      trimmedSkill === 'Motivational Coach';
    return (
      <p
        key={trimmedSkill}
        style={{
          background: isHighlighted ? 'black' : 'transparent',
          color: isHighlighted ? 'white' : 'inherit',
          opacity: isHighlighted ? '0.8' : '1',
          fontSize: "0.9rem"
        }}
        className="skillStyle"
      >
        {entitySkill}
      </p>
    );
  });


  const EntityImageComponent = () => {
    return (<div style={{margin:"3%"}}><img
      onClick={handleDialogOpen}
      className="entityImage"
      src={entityImageSrc}
      style={{ borderRadius: '20px', width: '80%' }}
        alt={entityName}
      />
    </div>)
  }

  const SkillsDisplayComponent = () => {
    return (<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', margin:"2%" }}>{skillsDisplay}</div>)
  }

  const KundliReportBuyButton = () => {
    return (<Button style={{marginBottom:"5%"}} className="discoverChatStyle2" onClick={handleDialogOpen}>{entityName}  <s style={{ margin: "5px" }}>₹799</s>  (₹299)</Button>)
  }

  const SampleReportViewer = () => {
    return (<div style={{marginBottom:"7%"}} ><h3 style={{ fontWeight: "500" }}>Sample Report</h3>
        <br />

        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
          <div style={{
            height: '500px',
            width: "100%",
            margin: "none !important"
          }}>
            <Viewer fileUrl={`${sampleReport}`} />
          </div>
        </Worker></div>)
  }

  const { width, height } = useWindowSize();
  const [showConfetti, setShowConfetti] = useState(false);

  return (

    <>

      {!loading &&

        <div>
            
          <EntityImageComponent />
          
          <SkillsDisplayComponent />

          <div style={{paddingLeft:"10%", paddingRight:"10%", lineHeight:"1.5rem"}}>
            <div style={{fontSize:"1rem", color:"gray", marginBottom:"5px"}}>Did You Know?</div> 
            <div style={{fontSize:"0.9rem", color:"gray", textAlign:"center"}}>Powerful planetary combinations can cancel effect of low Kundli Score</div>
          </div>  
          <br/>
          <br/>
          
          <KundliReportBuyButton />
          <QuestionsDisplay sampleQuestions={samepleQuestions} />
          <SampleReportViewer />
          <KundliForm dialogOpen={dialogOpen} handleDialogClose={handleDialogClose} setUserEmail={setUserEmail} setUserContact={setUserContact} 
          setBoyDob={setBoyDob}
          setBoyTob={setBoyTob} 
          setBoyPlace={setBoyPlace} 
          setGirlDob={setGirlDob}
          setGirlTob={setGirlTob} 
          setGirlPlace={setGirlPlace} 
          handleConfirm={handleConfirm}
          userEmail={userEmail} 
          userContact={userContact} 
          boyDob={boyDob} 
          boyTob={boyTob} 
          boyPlace={boyPlace} 
          girlDob={girlDob} 
          girlTob={girlTob} 
          girlPlace={girlPlace} 
          errors={errors} />
          <KundliReportBuyButton />
          <SuccessPopup
            open={openPopup}
            onClose={handleClosePopup}
          />
        </div>}
    </>
  );
};

/**
 * const [boyDob, setBoyDob] = useState(dob || localStorage.getItem("dob"));
  const [boyTob, setBoyTob] = useState(tob || localStorage.getItem("tob"));
  const [boyPlace, setBoyPlace] = useState(city || localStorage.getItem("city"));

  const [girlDob, setGirlDob] = useState(dob || localStorage.getItem("dob"));
  const [girlTob, setGirlTob] = useState(tob || localStorage.getItem("tob"));
  const [girlPlace, setGirlPlace] = useState(city || localStorage.getItem("city"));
 */

export default KundliCard;