import React from "react";
import "./BasicChat.css";
import { useNavigate } from "react-router-dom";
import { Button } from '@mui/material';

const Footer = ({position = "fixed"}) => {
    const location = useNavigate();

    const getStyle = (position) => {
        if(position == "fixed"){
            return { background: "black", position: position, opacity: "0.9", bottom: "0", width: "100%" };
        }else{
            return { background: "black", opacity: "1", bottom: "0", width: "100%" };
        }
    }

    return (
        <div className="footer" style={getStyle(position)}>
            <div className="footer__inner" style={{ padding: "1.5rem", color: "white", display: "flex", fontSize: "13px", fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}>

                <Button
                    style={{
                        paddingRight: "20px",
                        color: "black",
                        textTransform: "none",
                        display: "flex", 
                        flexDirection: "column", 
                        alignItems: "center", 
                        justifyContent: "center", 
                    }}
                    onClick={async _ => {
                        try {
                            window.open("https://www.instagram.com/astroskyofficial/");
                            window.gtag('event', 'Share');
                        } catch (_) {
                            console.error("Failed to Copy");
                        }
                    }}>
                    <img src="/icons/instagram.png" alt="Instagram" style={{ width: "40px", marginBottom:"6px", textDecoration: "none" }} />
                </Button>
            </div>

        </div>
    );
}

export default Footer;