import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Box, Paper } from '@mui/material';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { getUrl } from '../../helpers/envUrls';

const LuckyRudrakshaCalculator = () => {
  const [dob, setDob] = useState('');
  const [tob, setTob] = useState('');
  const [birthplace, setBirthplace] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [result, setResult] = useState('');

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 300,
  });

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      setLatitude(lat);
      setLongitude(lng);
      setBirthplace(address);
    } catch (error) {
      console.error('Error fetching latitude and longitude:', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Prepare data in required format
    const formattedDob = dob.split('-').reverse().join('/'); // Convert YYYY-MM-DD to DD/MM/YYYY

    const data = {
      dob: formattedDob,
      tob,
      lat: latitude,
      lon: longitude,
    };

    try {
      // Make the API call
      const response = await fetch(`${getUrl()}/get-horoscope-detail`, {
        method: 'POST',
        headers: {
            Accept: "application/json",
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "userDetail": data })
      });

      const result = await response.json();

      if (result.status === 200) {
        const zodiac = result.response['0'].zodiac;

        const rudrakshaRecommendation = getRudrakshaByZodiac(zodiac);

        setResult(`Based on your zodiac sign: <b>${zodiac}</b>,<br/><b>${rudrakshaRecommendation}</b> is the recommended Rudraksha for you!`);
      } else {
        setResult('Failed to retrieve data. Please try again.');
      }
    } catch (error) {
      setResult('Error fetching the data. Please check your inputs and try again.');
    }
  };

  const getRudrakshaByZodiac = (zodiac) => {
    const rudrakshaMapping = {
      Aries: '3 Mukhi Rudraksha',
      Taurus: '6 Mukhi Rudraksha',
      Gemini: '4 Mukhi Rudraksha',
      Cancer: '2 Mukhi Rudraksha',
      Leo: '12 Mukhi Rudraksha',
      Virgo: '10 Mukhi Rudraksha',
      Libra: '7 Mukhi Rudraksha',
      Scorpio: '9 Mukhi Rudraksha',
      Sagittarius: '5 Mukhi Rudraksha',
      Capricorn: '14 Mukhi Rudraksha',
      Aquarius: '8 Mukhi Rudraksha',
      Pisces: '5 Mukhi Rudraksha',
    };

    return rudrakshaMapping[zodiac] || 'Unknown Rudraksha';
  };

  return (
    <Container
      sx={{
        background: 'linear-gradient(to right, #ffeb3b, #e3f2fd)',
        borderRadius: '10px',
        padding: 4,
        boxShadow: 3,
        maxWidth: '500px',
        margin: 'auto',
        marginTop: 5,
      }}
    >
    <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{ textAlign: 'center', color: '#fff', marginBottom: 2 }}
        >
            Lucky Rudraksha Calculator
    </Typography>
    <form onSubmit={handleSubmit}>
    <TextField
        fullWidth
        label="Date of Birth (DD-MM-YYYY)"
        variant="outlined"
        margin="normal"
        type="date"
        InputLabelProps={{ shrink: true }}
        value={dob}
        onChange={(e) => setDob(e.target.value)}
        required
    />
    <TextField
        fullWidth
        label="Time of Birth (HH:MM)"
        variant="outlined"
        margin="normal"
        type="time"
        InputLabelProps={{ shrink: true }}
        value={tob}
        onChange={(e) => setTob(e.target.value)}
        required
    />
    <TextField
        fullWidth
        label="Place of Birth"
        variant="outlined"
        margin="normal"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={!ready}
        required
    />
    {status === 'OK' && (
        <Box sx={{ maxHeight: 200, overflowY: 'auto' }}>
        {data.map(({ description }) => (
            <Button
            key={description}
            fullWidth
            onClick={() => handleSelect(description)}
            sx={{ justifyContent: 'start' }}
            >
            {description}
            </Button>
        ))}
        </Box>
    )}
    <Button
        fullWidth
        variant="contained"
        color="primary"
        type="submit"
        sx={{ mt: 2 }}
    >
        Calculate Lucky Rudraksha
    </Button>
    </form>
    {result && (
    <Box
        sx={{
        padding: 3,
        marginTop: 3,
        backgroundColor: '#fff',
        color: '#003c8f',
        boxShadow: 2,
        }}
        component={Paper}
    >
    <Typography
    variant="body1"
    component="div"
    dangerouslySetInnerHTML={{ __html: result }}
    />
    </Box>
    )}
    </Container>
  );
};

export default LuckyRudrakshaCalculator;
