import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import './blogDetails.css';
import AtomicSpinner from 'atomic-spinner';
import { Helmet } from 'react-helmet';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import { useStateValue } from '../../statemanagement/StateProvider';

const BlogDetail = () => {
  const { path } = useParams(); // Use path instead of id
  const [blog, setBlog] = useState(null);
  const [recentBlogs, setRecentBlogs] = useState([]);
  const [{language}] = useStateValue();
  const API_URL = 'https://steadfast-cows-9445c3a50f.strapiapp.com/api/blogs';

  useEffect(() => {
    const fetchBlogByPath = async () => {
      try {
        // Fetch the blog by path
        const response = await axios.get(`${API_URL}?filters[path][$eq]=${path}&populate=*&locale=${language}`);
        if (response.data && response.data.data.length > 0) {
          setBlog(response.data.data[0]); // Set the first blog that matches the path
        } else {
          console.error('Blog not found', response);
        }
      } catch (error) {
        console.error('Error fetching blog:', error);
      }
    };

    const fetchRecentBlogs = async () => {
      try {
        const response = await axios.get(`${API_URL}?sort=createdAt:desc&pagination[limit]=10&populate=*&locale=${language}`);
        if (response.data && response.data.data.length > 0) {
          setRecentBlogs(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching recent blogs:', error);
      }
    };

    fetchBlogByPath();
    fetchRecentBlogs();
  }, [path, language]);

  if (!blog) {
    return <div className='spinner'>
            <AtomicSpinner/>
          </div>
  }

  const blogContent = blog.attributes.content;
  const {seo} = blog.attributes;
  const blogThumbnailData = blog.attributes.thumbnail?.data;
  const blogThumbnail = blogThumbnailData && blogThumbnailData.length > 0 
    ? blogThumbnailData[0].attributes.url 
    : '';

  const generateMetaTags = () => {
    const metaDescription = seo?.metaDescription || extractFirst160Characters(blogContent) || '';   
    const primaryKeywords = seo?.primaryKeywords || blog.attributes.title || '';
    const secondaryKeywords = seo?.secondaryKeywords || (blogContent[0]?.type === 'heading' ? blogContent[0].children[0].text : '') || '';
    const canonicalURL = seo?.canonicalURL || `https://hiastro.co.in/blog/${path}`;

    return {
      metaDescription,
      metaKeywords: { primaryKeywords, secondaryKeywords },
      metaRobots: 'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1',
      metaViewport: 'width=device-width, initial-scale=1',
      canonicalURL
    };
  };

  const extractFirst160Characters = (content) => {
    if (Array.isArray(content)) {
      const text = content
        .map(item => item.children.map(child => child.text).join(' '))
        .join(' ');
      return text.slice(0, 160).trim();
    }
    return '';
  };
  
  const metaTitle = seo?.metaTitle || blog.attributes.title;
  const { metaDescription, metaKeywords, metaRobots, metaViewport, canonicalURL } = generateMetaTags();
  
    const renderContent = (content) => {
      return content.map((item, index) => {
        const { type, level } = item;
        switch (type) {
          case 'heading':
            const HeadingTag = `h${level}`;
            return <HeadingTag key={index} style={{ fontWeight: item.children[0].bold ? 'bold' : 'normal' }}>{item.children[0].text}</HeadingTag>;
          case 'paragraph':
              return (
                <p key={index}>
                  {item.children.map((child, idx) => {
                    if (child.type === 'link' && child.url) {
                      console.log(child);
                      return (
                        <a href={child.url} key={idx} target="_blank" rel="noopener noreferrer">
                          {child.children.map(grandchild => grandchild.text).join('')}
                        </a>
                      );
                    }
                    if (child.bold) {
                      return <strong key={idx}>{child.text}</strong>;
                    }
                    return child.text;
                  })}
                </p>
              );
            default:
              return null;
      }
      });
    };

  return (
  <div className="blog-detail-container">
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={`${metaKeywords.primaryKeywords}${metaKeywords.secondaryKeywords ? `, ${metaKeywords.secondaryKeywords}` : ''}`} />  
    <meta name="robots" content={metaRobots} />
    <meta name="viewport" content={metaViewport} />
    <link rel="canonical" href={canonicalURL} />
  </Helmet>
  <div className="blog-detail">
    <div className="blog-content">
      <h1>{blog.attributes.title}</h1>
      {blogThumbnail && (
        <div className="blog-image-container">
          <img
            src={blogThumbnail}
            alt={blog.attributes.title}
            className="blog-detail-image"
          />
        </div>
      )}
      <div className="blog-text-content">
        {renderContent(blogContent)}
      </div>
    </div>
  </div>
  <div className="recent-blogs">
    <div className="recent-blogs-header">
      <ScheduleRoundedIcon/>
      <h3>Recent Blogs</h3>
    </div>
    <ul className="recent-blogs-list">
      {recentBlogs.map((recentBlog) => (
        <li key={recentBlog.id}>
          <Link to={`/blog/${recentBlog.attributes.path}`}>
            {recentBlog.attributes.title}
          </Link>
        </li>
      ))}
    </ul>
  </div>
</div>
  );
};

export default BlogDetail;
