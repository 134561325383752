import mixpanel from 'mixpanel-browser';

let isInitialized = false;

const mixpanelService = {

  initMixpanel() {
    const mixpanelToken = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN;
    if (mixpanelToken) {
        mixpanel.init(mixpanelToken, {
            debug: true,
            track_pageview: true,
            persistence: "localStorage",
            batch_requests: true
        });
        isInitialized = true;
        console.log("Mixpanel initialized");
    } else {
        console.warn("Mixpanel token is missing. Analytics will not be tracked.");
    }
  },

  getIsInitialized() {
    return isInitialized;
  },

  identifyUser(userId, properties = {}) {
    if (!isInitialized) {
        console.warn("Mixpanel is not initialized.");
        return;
    }
    mixpanel.identify(userId);
    mixpanel.people.set(properties);
  },

  setUserProperties(properties = {}) {
    mixpanel.register(properties);
  },

  trackEvent(eventName, properties = {}) {
    if (!isInitialized) {
        console.warn("Mixpanel is not initialized.");
        return;
    }
    mixpanel.track(eventName, properties);
  },

  reset() {
    mixpanel.reset();
  },
};

export default mixpanelService;