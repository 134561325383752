import React from 'react';
import { useNavigate } from "react-router-dom"
import Header from './Header';
import Footer from './Footer';

const Custom = () => {

    const navigate = useNavigate();

    return (
        <div>
            <div className="container">
                <Header showLogin={false} />
                <div style={{ marginTop: "2%" }}>
                    <div className="">
                        <div className="privacy-policy" style={{ marginTop: "7%" }}>
                            
                            <h3>Create a Custom AI App/Bot with your own data</h3>
                            <br />
                            <p>Kindly reach out to us with your idea, project details at <a href="mailto:support@chatdragon.ai">support@chatdragon.ai</a>.</p>
                            <br />
                      
                            <br />
                           
                            <br />
                        </div>
                        <br />

                    </div>
                    <br />

                </div>

            </div>
            <Footer/>
        </div>


    );
};

export default Custom;
