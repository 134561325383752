import { useEffect } from "react";
import { useStateValue } from "../../statemanagement/StateProvider";
import { doc, onSnapshot} from "firebase/firestore";
import { db } from "../../config/firebase";
import { actionTypes } from "../../statemanagement/reducer";
import { upgradeToPremium } from "../AuthenticateUser";
import axios from "axios";

const UserListener = () => {
    const [{ uid, phoneHash }, dispatch] = useStateValue(); 


    
    useEffect(()=>{

        let order_id = localStorage.getItem("order_id");
        if(!order_id){
          const params = new URLSearchParams(window.location.search);//http://localhost:3000/?order_id=1323caef4f6e
          order_id = params.get("order_id");
        }

        localStorage.removeItem("order_id");
        
        if(!!order_id && order_id != ""){
            verifyPayment(order_id).then((res)=>{
                if(res.data[0].payment_status == "SUCCESS"){

                  upgradeToPremium(localStorage.getItem("uid")).then(()=>{
                    console.log("user has been upgraded to premium");
                  })
                }
            }).catch((err)=>{

            })
        }
        
    },[])

    const verifyPayment = async (orderId) => {
        const res = axios.post("https://paymentgatewayservice1964.azurewebsites.net/verify",{
            order_id: orderId
        });
        return res;
    }


    useEffect(() => {

        if (!uid) return;

        const userRef = doc(db, "users", uid);
        const unsubscribe = onSnapshot(userRef, (userCredSnapshot) => { 
            if (userCredSnapshot.exists()) {
                dispatch({ type: actionTypes.SET_USER_CRED, userCred: userCredSnapshot.data() });
            }
        });

        return () => unsubscribe();

    }, [uid]);


    useEffect(()=>{
        if(!phoneHash) return;

        console.log("phone hash has been set to local state");

        const userRef = doc(db, "users", phoneHash);
        console.log("start listening");

        const unsubscribe = onSnapshot(userRef, (userSnapshot) => {
            if(userSnapshot.exists()){
                dispatch({type: actionTypes.SET_USER_CRED, userCred: userSnapshot.data()}); 
            }
        })


    },[phoneHash])



    // useEffect(() => {

    //     console.log("LISTENING TO TIER UPDATES FROM DB");

    //     if (!uid) return;

    //     const tierRef = doc(db, "tiers", uid);
    //     const unsubscribe = onSnapshot(tierRef, (snapshot) => { 
    //         if (snapshot.exists()) {
    //             // dispatch({ type: actionTypes.SET_USER_CRED, userCred: userSnapshot.data() });
    //             console.log("printing tier snapshot")
    //             console.log(snapshot.data());
    //             dispatch({type: actionTypes.SET_TIER, tier: snapshot.data()})
    //         }
    //     });

    //     return () => unsubscribe();

    // }, [uid]);

    // useEffect(() => {
    //     if (!userCred) return; //not logged in


    //     //logged in, push to db

    //     const updateQuestionsCount = async () =>{
    //         const userRef = doc(db, "users", userCred.uid);
    //         console.log("updating questions count in db");
    //         await updateDoc(userRef,{
    //             questions: increment(1) //update question count
    //         })
    //     }
    //     if(!!followup && followup.length>0){
    //         console.log("User has asked a question");
    //         updateQuestionsCount();
    //     }
        

    // }, [followup]);

    return null; 
};

export default UserListener;
