const Disclaimer = ({hindi=true}) => {
    return (
        <>
      
            <div style={{ marginTop: "3%", padding:"0.75rem" }}><i style={{ fontSize: "11px" }}>*Disclaimer: HiAstro utilizes advanced AI to provide astrological insights, which may contain errors or inaccuracies. We make no guarantees regarding the reliability of our content and assume no liability for actions taken based on this information. </i></div>
            <br />
            {hindi &&
            (<><div style={{ margin: "3%" }}><i style={{ fontSize: "11px" }}>*अस्वीकरण: एस्ट्रोस्काई AI द्वारा संचालित है, गलतियों के प्रति संवेदनशील है। प्रदान की गई जानकारी केवल मनोरंजन के उद्देश्यों के लिए है और इसे चिकित्सा, कानूनी, व्यावसायिक या अन्य सलाह के रूप में देखा नहीं जाना चाहिए। विशिष्ट चिंताओं के लिए उपयुक्त पेशेवरों से परामर्श लें। कृपया उत्पन्न टेक्स्ट और ऑडियो को सावधानी से समीक्षा करें। </i></div>
            <br /><br /></>)}
        </>

    );
};

export default Disclaimer;

//*Disclaimer: AstroSky is powered by AI, prone to errors. Information offered is for entertainment purposes only. and should not be seen as medical, legal, professional or other advice. Consult appropriate professionals for specific concerns. Please review the generated text and audio carefully