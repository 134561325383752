import React from "react";
import "./WeeklyViewStyles.css";
import Header from "./Header";
import AppFooter from "./AppFooter";

function WeeklyView() {
  const images = [
    { src: "https://astrosky.blob.core.windows.net/astrosky/Aries.png" },
    { src: "https://astrosky.blob.core.windows.net/astrosky/Taurus.png" },
    { src: "https://astrosky.blob.core.windows.net/astrosky/Gemini.png" },
    { src: "https://astrosky.blob.core.windows.net/astrosky/Cancer.png" },
    { src: "https://astrosky.blob.core.windows.net/astrosky/Leo.png" },
    { src: "https://astrosky.blob.core.windows.net/astrosky/Virgo.png" },
    { src: "https://astrosky.blob.core.windows.net/astrosky/Libra.png" },
    { src: "https://astrosky.blob.core.windows.net/astrosky/Scorpio.png" },
    { src: "https://astrosky.blob.core.windows.net/astrosky/Saggitarius.png" },
    { src: "https://astrosky.blob.core.windows.net/astrosky/Capricorn.png" },
    { src: "https://astrosky.blob.core.windows.net/astrosky/Aquarius.png" },
    { src: "https://astrosky.blob.core.windows.net/astrosky/Pisces.png" },
  ];

  return (
    <div className="astroSkyLandingPageCommon">
      <Header showLogin={true} />
      <div className="container">
        <p>Weekly Horoscope</p>
        <div className="container_body">
            
          <div className="reels-container">
            {images.map((image, index) => (
              <div key={index} className="reel">
                <img src={image.src} alt={`Image ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <AppFooter
        showHome={false}
        showSearch={false}
        showNumerology={false}
        showWrite={true}
        showBack={false}
      />
    </div>
  );
}

export default WeeklyView;
