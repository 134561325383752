import React from 'react';
import { useNavigate } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import AppFooter from './AppFooter';
import { Button } from '@mui/material';
import { TypeAnimation } from "react-type-animation";

const Feedback = () => {
    const navigate = useNavigate();

    return (
        <div>
            <div className="container" style={{ padding: "3.9%" }}>
                <Header showLogin={false} />
                <div style={{ marginTop: "5%" }}>
                    <div className="">
                        <div className="privacy-policy" style={{ marginTop: "5%" }}>
                        <TypeAnimation sequence={[
                            'Help us improve HiAstro',
                            1000,
                            'Thank You 🙏',
                        ]}  wrapper="span" speed={50} style={{ marginBottom:"15px", textAlign:"center",fontSize: "1.3em", fontWeight:"500", lineHeight: "2.1rem", display: "inline-block", color: "black" }} repeat={0} />

                            <br/>
                            {/* <div style={{textAlign:"center"}}> 
                                <img src="https://astrosky.blob.core.windows.net/astrosky/yogi.png" style={{width:"250px", borderRadius:"10%"}}/>
                            </div> */}
                           <br/>
                            
                            <Button style={{width:"88vw", height:"50px", background:"rgb(255, 240, 0)", color:"black", borderRadius:"8px", boxShadow:"0 2px 5px 0 rgba(213,217,217,.5)", textTransform:"none", fontSize:"1rem"}} onClick={(_) =>{
                                window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSd3JebrFU2p2SvllKgQXOcL42YUfcUT2On6DPuxMAjCAngY-Q/viewform?usp=sf_link";
                            } }>Submit Feedback (Lucky Draw)</Button>
                           <br/>
                           <br/>
                           <br/>
                           <div style={{textAlign:"center"}}> 
                                <img src="https://astrosky.blob.core.windows.net/astrosky/yogi.png" alt="yogi" style={{width:"300px", borderRadius:"10%"}}/>
                            </div>
                           {/* <h4 style={{ fontWeight: 500 }}><i>*AI will determine lucky draw winners based on feedback</i></h4> */}
                            <br/>
                        </div>
                    </div>
                </div>
            </div>
            <br/><br/><br/><br/>
            <AppFooter home={true}/>
        </div>
    );
};

export default Feedback;

