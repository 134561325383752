import React from 'react';
import { useNavigate } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import AppFooter from './AppFooter';

const RefundCancellationPolicy = () => {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h2>Cancellation Policy</h2> <br/><br/>
      <p>
        <strong>Arcenia Tech Private Limited</strong><br /> <br/>
        Address: G-9/15A, DLF Phase 1, Gurgaon, Haryana 122002, India
      </p><br /> <br/>
      <p>Contact: <a href="mailto:support@astrosky.co.in">support@astrosky.co.in</a></p><br /> <br/>
      <p>
        At Arcenia Tech Private Limited, we strive to ensure that our customers are fully satisfied with our digital services. 
        If, for any reason, you are not satisfied with your purchase, we offer a refund policy to ensure your experience with us 
        is as smooth as possible.
      </p> <br /> <br/>
      
      HiAstro's astrological calculations are derived from scientific methods and are not based on any specific published almanac. While our reports are generally rooted in Indian Predictive Astrology, they should be viewed as the independent interpretations of HiAstro. The predictive texts provided are intended as opinions based on astrological principles. HiAstro will not address disputes arising from differences in astrological interpretations but will respond to customer complaints or feedback within a reasonable timeframe to clarify any points related to our predictions.

      HiAstro strives to ensure that each report is prepared with the highest level of accuracy and care. However, we acknowledge that errors may occur. In such cases, HiAstro's responsibility is limited to providing a corrected version of the report.

      {/* <h2>Refund Policy</h2> <br /> <br/>
      <h3>Eligibility for Refund</h3> <br /> <br/>
      <ul>
        <li>Refund requests must be made within 1 week (7 days) from the date of purchase.</li>
        <li>Refunds are applicable to all digital services, including those purchased at a discount.</li>
        <li>Services marked as non-refundable at the time of purchase are not eligible for a refund.</li>
      </ul> <br /> <br/>
      <h3>Refund Timeline</h3> <br /> <br/>
      <p>Refunds will be processed within 7 days from the date of request. If there are any delays, customers will be notified via email.</p> <br /> <br/>
      <h3>Conditions for Refund</h3> <br /> <br/>
      <ul>
        <li>Refunds can be requested without the need for a receipt.</li>
        <li>Digital services can be refunded if they have not been delivered or used.</li>
        <li>Issues with the digital services should be reported and are eligible for a refund if the service is deemed unsatisfactory or faulty.</li>
      </ul> <br /> <br/>
      <h3>Refund Process</h3> <br /> <br/> */}
    <br/> <br/>
      <h3>Refund Policy</h3>
      <br/> <br/>
      <ul>
        <li>No refunds will be provided for report orders once they reach the "processing" stage, which means the order has been assigned to the HiAstro team.</li>
        <li>The responsibility for placing an order quickly or carelessly lies entirely with the User, and the Website is not obligated to issue a refund once processing has begun.</li>
        <li>No refunds will be granted after an order is placed and executed.</li>
        <li>If a User wishes to cancel an order before it is executed, they must contact customer support within one hour of payment.</li>
        <li>After the one-hour window, issuing a refund is at the sole discretion of the Website.</li>
      </ul>
      <br/> <br/>


      <p>
        For any queries or clarifications, please contact us at <a href="mailto:support@astrosky.co.in">support@astrosky.co.in</a><br /> <br /> <br/>
      </p> <br /> <br/>
      <p>
        We value your feedback and are here to assist you with any issues you may encounter with our digital services. 
        Your satisfaction is our priority.
      </p> <br /> <br/>
      <p>
        <strong>Arcenia Tech Private Limited</strong><br />
        G-9/15A, DLF Phase 1, Gurgaon, Haryana 122002, India<br />
        Email: <a href="mailto:support@astrosky.co.in">support@astrosky.co.in</a>
      </p>
      <br /> <br/><br /> <br/><br /> <br/><br /> <br/>
    </div>
  );
};

const Cancellation = () => {
  const navigate = useNavigate();

  const CancellationPolicy = () => {
    return (
      <div className="cancellation-policy">
        <RefundCancellationPolicy />
      </div>
    );
  };

  return (
    <div>
      <div className="container">
        <Header showLogin={false} />
        <div style={{ margin: "3%" }}>
          <CancellationPolicy />
        </div>
      </div>
      <AppFooter />
    </div>
  );
};

export default Cancellation;
