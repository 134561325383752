import { useLocation, useNavigate } from "react-router-dom";
import Header from './Header';
import AppFooter from './AppFooter';
import { useStateValue } from '../statemanagement/StateProvider';
import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, Button } from '@mui/material';
import axios from "axios";
import {load} from "@cashfreepayments/cashfree-js";
import { actionTypes } from "../statemanagement/reducer";
//import { functions } from "../config/firebase"
//import { httpsCallable } from "firebase/functions";
import UserListener from "./FirestoreListener/userListener";
import { TypeAnimation } from "react-type-animation";
import UseBirthDetails from "./UseBirthDetails";

const Profile = () => {

    UserListener();
    UseBirthDetails();

    //Init SDK
    let cashfree;
    let initializeSDK = async () => {
        cashfree = await load({
            mode: "production"
        })
    }

    initializeSDK();

    const navigate = useNavigate();
    const [{tier, userCred},dispatch ] = useStateValue();
    const location = useLocation();
    const [priceText, setPriceText] = useState('Full Access @ ₹25');
    const [appliedDiscount, setAppliedDiscount] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);

    // const updateUserTier = httpsCallable(functions, "updateUserTier");//updateUserQuestions
    //const updateQuestionsCloudFunction = httpsCallable(functions, "updateUserQuestions");
    

    //move higher
    // useEffect(()=>{
    //     //on component mount
    //     let order_id = localStorage.getItem("order_id");
    //     if(!order_id){
    //       const params = new URLSearchParams(location.search);//http://localhost:3000/?order_id=1323caef4f6e
    //       order_id = params.get("order_id");
    //     }

    //     localStorage.removeItem("order_id");
        
    //     if(!!order_id && order_id != ""){
    //         verifyPayment(order_id).then((res)=>{
    //             if(res.data[0].payment_status == "SUCCESS"){

    //               upgradeToPremium(localStorage.getItem("uid")).then(()=>{
    //                 console.log("user has been upgraded to premium");
    //               })
    //             }
    //         }).catch((err)=>{

    //         })
    //     }

    // },[])


    // const verifyPayment = async (orderId) => {
    //     const res = axios.post("https://paymentgatewayservice1964.azurewebsites.net/verify",{
    //         order_id: orderId
    //     });
    //     return res;
    // }

    //create order, call backend api
    const createOrder = async () => { //create order = session_id and order_id stored in cashfree DB 
        const result = await axios.post("https://paymentgatewayservice1964.azurewebsites.net/createOrder",{
            "phone": userCred.phone,
            "uid": userCred.uid
        });
        return {"session":result.data.payment_session_id, "orderId":result.data.order_id}
    }

   
    // useEffect(() => {
    //   if (showConfetti) {
    //     const timer = setTimeout(() => {
    //       setShowConfetti(false);
    //     }, 3000); // Show confetti for 3 seconds
    //     return () => clearTimeout(timer);
    //   }
    // }, [showConfetti]);


    const renderUserCard = (usercred) => {
        //const { phone, logintime } = usercred;
        const { uid, phone, tier } = usercred;
        
     //   const {premiumAnswerCount, tier} = tierData
       // const loginTime = new Date(logintime.seconds * 1000).toLocaleString();
      
        return (
        
          <Card style={{ maxWidth: 400, margin: 'auto', marginTop: 20 }}>
            <CardContent>
              <Typography variant="h5" component="div" gutterBottom>
                My Profile Page
              </Typography>
              <br/>
              <Grid container spacing={2}>
              {/* <Grid item xs={12}>
                  <Typography variant="body2" color="textSecondary">
                    <strong>UID:</strong> {uid}
                  </Typography>
                </Grid> */}
                {/* <Grid item xs={12}>
                  <Typography variant="body2" color="textSecondary">
                    <strong>User ID:</strong> {uid}
                  </Typography>
                </Grid> */}
                {/* <Grid item xs={12}>
                  <Typography variant="body2" color="textSecondary">
                    <strong>Tier:</strong> {tier}
                  </Typography>
                </Grid> */}
                <Grid item xs={12}>
                  <Typography variant="body1" color="textSecondary">
                    <strong>Phone:</strong> {phone}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" color="textSecondary">
                   {(tier == "free") &&  <strong>Tier: Basic</strong> }
                   {(tier == "premium") &&  <strong>Tier: Premium</strong> }
                  </Typography>
                </Grid>

                
{/* 
                <Grid item xs={12}>
                  <Typography variant="body1" color="textSecondary">
                   <strong>Fees to run App:</strong> ₹5 
                  </Typography>
                </Grid> */}
           

                <Grid item xs={12}>
                  <Typography variant="body2" color="textSecondary">
           
                   {(tier == "premium") &&  (<Button className="chatStyle" onClick={async (_) => {
                  navigate("/discover");
                  }}>View AI Astrologers</Button>) }
                  </Typography>
                </Grid>

                {/* <Grid item xs={12}>
                   {userCred.tier == "free" && !appliedDiscount && <Button onClick={async (e)=>{
                    setPriceText('Full Access @ ₹21');
                   setShowConfetti(true);
                   setAppliedDiscount(true);
                    
                   }} className="chatStyle">Apply Offer</Button>}
                </Grid> */}
                

                <Grid item xs={12}>
                   {userCred.tier == "free" && <Button onClick={async (e)=>{
                    
                    const orderResponse = await axios.post(`https://paymentgatewayservice1964.azurewebsites.net/createOrder`,{
                      "phone": userCred.phone.replace("+91", "")
                    });

                    const session_id = orderResponse.data.payment_session_id;
                    const order_id = orderResponse.data.order_id;

                    console.log(`Session id is: ${session_id}`);
                    console.log(`Order id is: ${order_id}`);
                
                    localStorage.setItem("order_id",order_id);
                    
                    console.log("loading");
                    cashfree.checkout({
                      paymentSessionId: session_id
                      }).then((res)=>{
                          console.log(res);
                          console.log("done loading");
                      }); 
                   }} className="chatStyle">Full Access (₹5 only)</Button>}
                </Grid>

                {/* <Grid item xs={12}>
                   {userCred.tier == "free" && <Button onClick={async (e)=>{
                    
                    const orderResponse = await axios.post(`https://paymentgatewayservice1964.azurewebsites.net/createOrder`,{
                      "phone": userCred.phone.replace("+91", "")
                    });

                    const session_id = orderResponse.data.payment_session_id;
                    const order_id = orderResponse.data.order_id;

                    console.log(`Session id is: ${session_id}`);
                    console.log(`Order id is: ${order_id}`);
                
                    localStorage.setItem("order_id",order_id);
                    
                    console.log("loading");
                    cashfree.checkout({
                      paymentSessionId: session_id
                      }).then((res)=>{
                          console.log(res);
                          console.log("done loading");
                      }); 
                   }} className="chatStyle">Go Ultra* (<s> ₹99 </s>) ₹39 </Button>}
                </Grid> */}

                {/* {!!userCred.tier && <Grid item xs={12}>
                   Tier: {userCred.tier}
                </Grid>} */}
              
                {/* <Grid item xs={12}>
                   <Button onClick={(e)=>{
                    localStorage.removeItem("uid");
                    dispatch({type: actionTypes.SET_USER_CRED, userCred: null })
                    navigate("/");
                   }} className="chatStyle">Sign Out</Button>
                </Grid> */}
                {/* <Grid item xs={12}>
                  <Typography variant="body2" color="textSecondary">
                    <strong>Last Login Time:</strong> {loginTime}
                  </Typography>
                </Grid> */}
                {/* <Grid item xs={12}>
                  <Typography variant="body2" color="textSecondary">
                    <strong>Referral Code:</strong> {referralCode}
                  </Typography>
                </Grid> */}
                {/* <Grid item xs={12}>
                  <Typography variant="body2" color="textSecondary">
                    <strong>Premium Answers Available:</strong> {premiumAnswerCount}
                  </Typography>
                </Grid> */}
              </Grid>
             
            </CardContent>
            <br/>
            <div style={{textAlign:"center"}}>
            <TypeAnimation sequence={[
                            'Powered By AI',
                        ]}  wrapper="span" speed={50} style={{ marginBottom:"15px", textAlign:"center",fontSize: "1.3em", fontWeight:"500", lineHeight: "2.1rem", display: "inline-block", color: "black" }} repeat={0} /><br/>
            </div>
            
                    {/* <div style={{textAlign:"center", fontWeight:"400", fontSize:"1.2rem"}}><strong>Unlimited Questions for 24 hours</strong></div> <br/>
                    <div style={{textAlign:"center", fontWeight:"400", fontSize:"1.2rem"}}><strong>Access to All Astrologers</strong></div> <br/>
                    <div style={{textAlign:"center", fontWeight:"400", fontSize:"1.2rem"}}><strong>₹3 (Platform Fee), ₹2 (Donation) </strong></div> <br/> */}
                    {/* <div style={{textAlign:"center", fontWeight:"400", fontSize:"1.2rem"}}>24 hour access</div> <br/> */}
                    

                    {/* <div style={{textAlign:"center", fontWeight:"450", fontSize:"1.3rem"}}><i>26,00,000+ questions answered</i></div> */}
                     
                     
                    <br/>
              {/* <div style={{textAlign:"center", fontWeight:"300", fontSize:"1rem"}}><i>* World Cup offer, Ask any number of questions (Until 17th June)</i></div> */}
              <br/>
            {/* <Button 
            onClick={async (e)=>{
                const {session, orderId} = await createOrder();
                cashfree.checkout({
                    paymentSessionId: session
                }).then((res)=>{
                    console.log(res);
                }); 

            }}
            style={{ width: "88vw", height: "50px", background: "rgb(255, 240, 0)", color: "black", borderRadius: "8px", boxShadow: "0 2px 5px 0 rgba(213,217,217,.5)", textTransform: "none", fontSize: "1.2rem" }}>₹19 for <span style={{ textDecoration: 'line-through', margin: '0 5px' }}>25</span> 50 Questions</Button> */}
         <Grid item xs={12}>
                   <Button onClick={(e)=>{
                    localStorage.removeItem("uid");
                    dispatch({type: actionTypes.SET_USER_CRED, userCred: null })
                    navigate("/");
                   }} className="chatStyle">Sign Out</Button>
                </Grid>
         <br/> <br/>
          </Card>
          
        );
      }

    return (
        <div>
            <div className="container" style={{ padding: "3.9%" }}>
                <Header showLogin={false} userCred={userCred} />
                <div style={{ marginTop: "2%" }}>
                   {!!userCred && renderUserCard(userCred)}

                   <br />
                            
                            {/* <b>Please set the correct birth details by clicking Edit button below, before downloading the weekly horoscope</b> */}
                            {/* <DownloadPDF userCred={userCred} navigate={navigate}/> */}
                            {/* <DownloadFree/> */}
                            <br /><br />  <br /><br /> 

                </div>
            </div>
            <br/><br/><br/><br/>
            <AppFooter/>
        </div>
    );
};

export default Profile;
