import React from 'react';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './logo.css';

const Logo = () => {
  const navigate = useNavigate();

  return (
    <div className="logo">
      <div className="logo-container" onClick={() => navigate('/')}>
        <img
          src='../../../icons/horoscope.png'
          alt="Logo"
          className="logo-image"
        />
        <div className="logo-text-container">
          <Typography variant="h6" className="logo-text">HiAstro</Typography>
          <Typography variant="caption" className="logo-subtext">Powered by AI</Typography>
        </div>
      </div>
      {/* <div className="motto">
        <h5><i>Quick, Modern & Accurate!</i></h5>
      </div> */}
    </div>
  );
};

export default Logo;
